import React, { FC } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import './Navigation.scss';

export const NavLink: FC<LinkProps> = ({
  to,
  children,
  ...rest
}) => {
  const { pathname } = useLocation();
  const isActive = pathname === to;
  const className = `navigation__link__label${isActive ? '--active' : ''}`;

  return (
    <Link className={className} to={to} {...rest}>
      {children}
    </Link>
  );
};
