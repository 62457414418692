import React, { FC } from 'react';
import {
  Card, Typography, Button,
} from 'antd';
import { DBForecastFull } from '../../api/types';
import '../DashBoard/LocationCard.scss';

interface ForecastCardProps {
  forecast: DBForecastFull;
  onDelete: (forecast: DBForecastFull) => void;
  onOpen?: (forecast: DBForecastFull) => void;
}
export const ForecastCard: FC<ForecastCardProps> = ({
  forecast,
  onDelete,
  onOpen,
}: ForecastCardProps) => (
  <Card
    hoverable
    className="location-card location-card--with-button"
    onClick={onOpen ? () => onOpen(forecast) : () => null}
  >
    <Typography className="location-card__title">
      {`${forecast.year} - ${forecast.name}`}
    </Typography>

    <Typography.Paragraph className="location-card__description">
      {forecast.description}
    </Typography.Paragraph>

    <div className="location-card__actions location-card__actions--horizontal">
      <Button
        className="location-card__link"
        type="link"
        onClick={(e) => {
          e.stopPropagation();
          onDelete(forecast);
        }}
      >
        Löschen
      </Button>

      {
        onOpen && (
          <Button
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              onOpen(forecast);
            }}
          >
            Anzeigen
          </Button>
        )
      }
    </div>
  </Card>
);
