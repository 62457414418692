import React, { FC } from 'react';
import { useParams } from 'react-router';
import { Alert, Typography } from 'antd';
import LoginForm from './LoginForm';

import './Login.scss';

export const Login: FC = () => {
  const { withNotification } = useParams();

  return (
    <div className="login">
      {
        withNotification && withNotification === 'fromLogout' && (
          <Alert
            className="login__alert"
            message={(
              <Typography.Text>
                Sie wurden erfolgreich abgemeldet.
                <br />
                Melden Sie sich erneut an, um das Prognosetool zu verwenden.
              </Typography.Text>
            )}
          />
        )
      }
      <LoginForm />
    </div>
  );
};
