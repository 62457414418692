import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  Card, Tag, Typography, Button, Row,
} from 'antd';
import { DBOperationDataIncomplete, DBLocation } from '../../api/types';
import './LocationCard.scss';

const createOperationDataTags: (
  operationData: DBOperationDataIncomplete[]
) => JSX.Element[] = (operationData) => {
  if (operationData.length > 3) {
    const years = operationData.map((od) => od.year);

    return [
      // Since we add new operation data in the beginning of the array,
      // we cannot rely on operationData to be sorted by year. So, we
      // need to compute the minimum and maximum ourselves.
      <Tag style={{ marginRight: 0 }} key="first">{Math.max(...years)}</Tag>,
      <span className="location-card__ellipsis" key="ellipsis">...</span>,
      <Tag key="last">{Math.min(...years)}</Tag>,
    ];
  }

  return operationData.map((op) => (
    <Tag key={op.year}>{op.year}</Tag>));
};

const createCountingTag: (
  singular: string,
  plural: string,
  length: number,
) => JSX.Element[] = (singular, plural, length) => {
  if (length === 0) return [];
  if (length === 1) return [<Tag key={plural}>{`1 ${singular}`}</Tag>];

  return [<Tag key={plural}>{`${length} ${plural}`}</Tag>];
};

const createDataTags: (
  location: DBLocation,
) => JSX.Element[] = (location) => {
  const { operationDataList, forecastList, scenarioList } = location;

  return [
    ...createOperationDataTags(operationDataList),
    ...createCountingTag('Prognose', 'Prognosen', forecastList.length),
    ...createCountingTag('Szenario', 'Szenarios', scenarioList.length),
  ];
};

interface Props {
  location: DBLocation;
  onEdit: (source: DBLocation) => void;
  onDelete: (id: number) => void;
}
export const LocationCard: FC<Props> = ({
  location,
  onEdit,
  onDelete,
}: Props) => (
  <Link to={`/location/${location.id}`}>
    <Card hoverable className="location-card">
      <Typography className="location-card__title">
        {location.name}
      </Typography>
      <Row>
        <Tag>
          {location.locationType}
        </Tag>
      </Row>
      <Row>
        {createDataTags(location)}
      </Row>

      <Typography.Paragraph className="location-card__address">
        {`${location.street || ''} ${location.houseNumber || ''}`}
        <br />
        {`${location.plz || ''} ${location.location || ''}`}
      </Typography.Paragraph>

      <div className="location-card__actions">
        <Button
          className="location-card__link"
          type="link"
          onClick={(e) => {
            e.preventDefault();
            onEdit(location);
          }}
        >
            Bearbeiten
        </Button>
        <Button
          className="location-card__link"
          type="link"
          onClick={(e) => {
            e.preventDefault();
            onDelete(location.id);
          }}
        >
            Löschen
        </Button>
      </div>
    </Card>
  </Link>
);
