import React, { FC } from 'react';
import ChangePasswordForm from './ChangePasswordForm';

import '../Login/Login.scss';

export const ChangePassword: FC = () => (
  <div className="login">
    <ChangePasswordForm />
  </div>
);
