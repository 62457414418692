import React, { createRef, useContext, useState } from 'react';
import {
  Alert,
  Modal,
  notification,
  Spin,
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { AxiosError } from 'axios';
import ForecastForm from './ForecastForm';
import { ActiveLocationContext } from '../../context/ActiveLocationStore';
import { CreateForecast } from '../../api/types';

interface CreateForecastModalProps {
  showModal: boolean;
  afterClose: () => void;
}

/**
 * @description Open a toast to inform the user the forecast was saved.
 */
const openSuccessfulSaveNotification = (): void => {
  notification.success({
    message: 'Prognose erfolgreich erstellt',
    placement: 'bottomRight',
  });
};

const CreateForecastModal = (
  { showModal, afterClose }: CreateForecastModalProps,
): JSX.Element => {
  const formRef = createRef<FormComponentProps>();
  const [formValidationError, setFormValidationError] = useState<string>('');
  const [showModalSpin, setShowModalSpin] = useState(false);
  const { activeLocation, createForecast } = useContext(ActiveLocationContext);

  const closeAndResetModal = (): void => {
    if (formRef && formRef.current) {
      formRef.current.form.resetFields();
    }
    setFormValidationError('');
    setShowModalSpin(false);
    afterClose();
  };

  const handleError = (error: AxiosError): void => {
    const { response } = error;
    if (response && response.status === 400) {
      let errorMessage = '';
      Object.keys(response.data).forEach((validationError) => {
        errorMessage += `${response.data[validationError]}\n`;
      });
      setFormValidationError(errorMessage);
    }
  };

  const handleSave = (): void => {
    if (activeLocation && formRef && formRef.current) {
      const { form } = formRef.current;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      form.validateFields(async (err: any, values: Omit<CreateForecast, 'location'>) => {
        if (!err) {
          setShowModalSpin(true);
          const createForecastData: CreateForecast = { ...values, location: activeLocation.id };
          createForecast(createForecastData).then(() => {
            form.resetFields();
            closeAndResetModal();
            openSuccessfulSaveNotification();
          }).catch((error: AxiosError) => {
            setShowModalSpin(false);
            handleError(error);
          });
        }
      });
    }
  };

  return (
    <Modal
      cancelText="Abbrechen"
      okText="Speichern"
      onCancel={() => closeAndResetModal()}
      onOk={() => handleSave()}
      title="Neue Prognose erstellen"
      visible={showModal}
    >
      <Spin spinning={showModalSpin}>
        <ForecastForm
          wrappedComponentRef={formRef}
        />
        {
          formValidationError !== '' && (
            <Alert
              type="error"
              message={formValidationError}
            />
          )
        }
      </Spin>
    </Modal>
  );
};

export default CreateForecastModal;
