import React, { FC } from 'react';
import { Layout } from 'antd';

import './CardBoard.scss';
import { BasicProps } from 'antd/es/layout/layout';

export const CardBoardContent: FC<BasicProps> = ({
  children,
  ...rest
}) => (
  <Layout.Content
    className="card-board__content"
    {...rest}
  >
    {children}
  </Layout.Content>
);
