import React, { FC } from 'react';
import { UserStore } from './UserStore';
import { LocationStore } from './LocationStore';
import { ActiveLocationStore } from './ActiveLocationStore';

export const Store: FC = ({ children }) => (
  <UserStore>
    <LocationStore>
      <ActiveLocationStore>
        {children}
      </ActiveLocationStore>
    </LocationStore>
  </UserStore>
);
