import React, {
  createContext, FC, useEffect, useState,
} from 'react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useCookies } from 'react-cookie';
import { LoginResponse } from '../api/types';
import { loginAPI, meAPI } from '../api';
import { UserStoreValue } from './types';


const jwtCookieName = 'jwtToken';

const handleAuthorization = (removeCookie: (name: string) => void) => (error: AxiosError) => {
  const { response } = error;
  if (response?.status === 401 || response?.status === 403) {
    // Delete jwt token session cookies
    removeCookie(jwtCookieName);
    axios.defaults.headers.authorization = undefined;
  }
  throw error;
};

const authorization: (jwtToken: string) => AxiosRequestConfig = (jwtToken) => ({
  headers: {
    authorization: `jwt ${jwtToken}`,
  },
});

export const UserContext = createContext<UserStoreValue>({
  loading: true,
  login: () => new Promise<void>(() => null),
  logout: () => null,
  axiosAuthConfig: {},
});

export const UserStore: FC = ({ children }) => {
  const [loginData, setLoginData] = useState<LoginResponse|undefined>(undefined);
  const [cookies, setCookie, removeCookies] = useCookies([jwtCookieName]);

  axios.interceptors.response.use((r) => r, handleAuthorization(removeCookies));

  const login: (email: string, password: string) => Promise<void> = (email, password) => (
    loginAPI(email, password).then((response) => {
      setLoginData(response);
      setCookie(jwtCookieName, response.token, { path: '/' });
    })
  );

  const logout: () => void = () => {
    setLoginData(undefined);
    removeCookies(jwtCookieName, { path: '/' });
  };

  useEffect(() => {
    // If we have a cookie but no loginData, we need to obtain it via  the me endpoint
    // We also need to reload the data, if the jwt token in our cookies does not match
    // the token in the login data.
    if (cookies[jwtCookieName] && (!loginData || cookies[jwtCookieName] !== loginData?.token)) {
      meAPI(authorization(cookies[jwtCookieName])).then((me) => setLoginData(
        { user: me, token: cookies[jwtCookieName] },
      ));
    } else if (loginData && !cookies[jwtCookieName]) {
      setLoginData(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies]);

  return (
    <UserContext.Provider
      value={{
        user: loginData?.user,
        loading: jwtCookieName in cookies && !loginData,
        login,
        logout,
        axiosAuthConfig: authorization(
          loginData?.token || cookies[jwtCookieName],
        ),
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
