import React, { createRef } from 'react';
import { FormComponentProps } from 'antd/es/form';
import { Button, Modal } from 'antd';
import { DBForecastFull } from '../../api/types';
import OperationForm from '../LocationDetail/OperationForm';
import { OperationDataStore } from '../../context/OperationDataStore';

interface ViewForecastModalProps {
  onClose: () => void;
  forecast: DBForecastFull|undefined;
}

const ViewForecastModal = (
  { onClose, forecast }: ViewForecastModalProps,
): JSX.Element => {
  const formRef = createRef<FormComponentProps>();

  return (
    <Modal
      visible={!!forecast}
      width="90vw"
      footer={
        <Button type="primary" onClick={() => onClose()}>Schließen</Button>
      }
      onCancel={() => onClose()}
      title={forecast?.name}
    >
      { forecast && (
        <div>
          <OperationDataStore
            initialValues={forecast}
            readOnlyMode
          >
            <OperationForm
              wrappedComponentRef={formRef}
            />
          </OperationDataStore>
        </div>
      )}
    </Modal>
  );
};

export default ViewForecastModal;
