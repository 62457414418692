import React, { forwardRef, useContext, useImperativeHandle } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { Form, Input } from 'antd';
import { OperationDataContext } from '../../context/OperationDataStore';
import { DataType } from '../../api/types';

const SaveScenarioForm = forwardRef<FormComponentProps, FormComponentProps>(
  (
    { form },
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      form,
    }));
    const { getFieldDecorator } = form;

    const { initialValues } = useContext(OperationDataContext);
    const { name, description, type } = initialValues || {};

    return (
      <Form layout="vertical">
        <Form.Item label="Bezeichnung des Szenarios">
          {getFieldDecorator('name', {
            rules: [
              { required: true, message: 'Bitte geben Sie eine Bezeichnung ein' },
            ],
            initialValue: type === DataType.Scenario ? name : undefined,
          })(
            <Input
              type="text"
              placeholder="Szenariobezeichnung"
              disabled={initialValues?.type === DataType.Scenario}
            />,
          )}
        </Form.Item>

        <Form.Item label="Beschreibung">
          {getFieldDecorator('description', {
            initialValue: type === DataType.Scenario ? description : undefined,
          })(
            <Input.TextArea rows={3} />,
          )}
        </Form.Item>
      </Form>
    );
  },
);

export default Form.create({ name: 'forecastForm' })(SaveScenarioForm);
