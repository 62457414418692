import React, { FormEvent, useState } from 'react';
import {
  Alert, Button, Card, Form, Input, Typography,
} from 'antd';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { Link } from 'react-router-dom';
import { passwordResetAPI, showGeneralError } from '../../api';

interface Props {
  form: WrappedFormUtils;
}

const ChangePasswordForm = ({ form }: Props): JSX.Element => {
  const [message, setMessage] = useState<string|undefined>(undefined);
  const { getFieldDecorator, validateFields } = form;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    validateFields(async (err, values: {email: string}) => {
      if (!err) {
        passwordResetAPI(values.email)
          .then(({ detail }) => setMessage(detail))
          .catch(() => showGeneralError());
      }
    });
  };

  return (
    <Card style={{ width: '400px' }}>
      <Typography.Paragraph strong>
        Password vergessen oder ändern
      </Typography.Paragraph>

      <Typography style={{ marginBottom: 10 }}>
        Geben Sie die E-Mailadresse an, mit der Sie im System
        angemeldet sind, und klicken Sie auf &bdquo;Passwort ändern&ldquo;.
        Dann wird Ihnen eine E-Mail mit einem Link zugeschickt.
        Dieser Link führt Sie auf eine Seite, auf der Sie Ihr Passwort neu setzen können.
      </Typography>

      { message ? (
        <Alert
          type="success"
          message={(
            <Typography>
              {message}
            </Typography>
          )}
        />
      ) : (
        <Form onSubmit={handleSubmit}>
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [
                { type: 'email', message: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.' },
                { required: true, message: 'Bitte geben Sie Ihre E-Mail-Adresse ein.' },
              ],
              initialValue: '',
            })(
              <Input placeholder="E-Mail-Adresse" />,
            )}
          </Form.Item>

          <Form.Item>
            <Button block type="primary" htmlType="submit">Passwort ändern</Button>
          </Form.Item>
        </Form>
      )}
      <Typography style={{ marginTop: 10 }}>
        <Link to="/login">
          Zurück zur Anmeldeseite
        </Link>
      </Typography>
    </Card>
  );
};

export default Form.create({ name: 'changePassword' })(ChangePasswordForm);
