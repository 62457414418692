import React, { FC, useContext, useState } from 'react';
import { ModalProps } from 'antd/es/modal';
import {
  Alert, Button, Modal, notification, Typography, Upload,
} from 'antd';
import { RcFile } from 'antd/es/upload';
import { AxiosError } from 'axios';
import { Link } from 'react-router-dom';
import { uploadCsvAPI } from '../../api';
import { UserContext } from '../../context/UserStore';
import { ActiveLocationContext } from '../../context/ActiveLocationStore';


const showSuccessMessage = () => notification.success({
  message: 'Importieren erfolgreich!',
});

export const ImportDataModal: FC<ModalProps> = ({
  afterClose,
  ...modalProps
}) => {
  const [chosenFile, setChosenFile] = useState<RcFile|undefined>(undefined);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const { axiosAuthConfig } = useContext(UserContext);
  const { activeLocation, reloadActiveLocation } = useContext(ActiveLocationContext);

  const reset = () => {
    setChosenFile(undefined);
    setErrorMsg('');
    reloadActiveLocation();
  };

  const upload = () => {
    if (chosenFile && activeLocation) {
      uploadCsvAPI(chosenFile, activeLocation.id, axiosAuthConfig)
        .then(() => reset())
        .then(() => afterClose && afterClose())
        .then(showSuccessMessage)
        .catch((error: AxiosError) => {
          reset();
          if (afterClose) afterClose();
          if (error.response && error.response.status === 400) {
            Modal.error({ content: error.response.data[0] });
          }
        });
    }
  };

  return (
    <Modal
      title="Einsatzdaten per CSV hochladen"
      okText="Hochladen"
      cancelText="Abbrechen"
      onOk={upload}
      onCancel={() => afterClose && afterClose()}
      afterClose={() => {
        reset();
        if (afterClose) afterClose();
      }}
      okButtonProps={{ disabled: chosenFile === undefined }}
      {...modalProps}
    >
      <Typography>
        Über diesen Dialog können Sie eine .csv-Datei hochladen,
        die Einsatzdaten für beliebig viele Jahre enthalten. Diese
        werden dann automatisch als Einsatzdaten im Programm angelegt.
        Benutzen Sie bitte für das Format der Datei&nbsp;
        <Link
          download
          to="/assets/operationData/Einsatzdaten_Vorlage.csv"
          target="_blank"
        >
          diese
        </Link>
        &nbsp;Vorlage.
      </Typography>
      {errorMsg && (
      <Alert
        type="error"
        message={
        errorMsg.split('\n').map((errorText) => (
          <Typography>
            {errorText}
          </Typography>
        ))
      }
      />
      )}
      <Upload
        accept=".csv"
        beforeUpload={(file) => {
          setChosenFile(file);

          return false;
        }}
        fileList={chosenFile ? [chosenFile] : []}
        onRemove={() => setChosenFile(undefined)}
      >
        <Button
          style={{ marginTop: 20 }}
        >
          Datei auswählen
        </Button>
      </Upload>
    </Modal>
  );
};
