import React, {
  FC, FormEvent, useContext, useState,
} from 'react';
import { Redirect } from 'react-router';
import {
  Alert, Button, Card, Form, Input, Typography,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Link } from 'react-router-dom';
import { showGeneralError } from '../../api';
import { UserContext } from '../../context/UserStore';

import './Login.scss';


const LoginForm: FC<FormComponentProps> = ({ form }) => {
  const [loginError, setLoginError] = useState(false);

  const { user, login } = useContext(UserContext);

  const { getFieldDecorator, validateFields } = form;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setLoginError(false);
    validateFields(async (err, values) => {
      if (!err) {
        login(values.email, values.password)
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              setLoginError(true);
            } else {
              showGeneralError();
            }
          });
      }
    });
  };

  return (
    user ? <Redirect to="/" />
      : (
        <Card className="login__form">
          <Form onSubmit={handleSubmit}>
            <Typography.Paragraph strong>
              Bitte geben Sie Ihre E-Mailadresse und Passwort an:
            </Typography.Paragraph>

            <Form.Item>
              {getFieldDecorator('email', {
                rules: [
                  { type: 'email', message: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.' },
                  { required: true, message: 'Bitte geben Sie Ihre E-Mail-Adresse ein.' },
                ],
                initialValue: '',
              })(
                <Input placeholder="E-Mail-Adresse" />,
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('password', {
                rules: [
                  { required: true, message: 'Bitte geben Sie Ihr Passwort ein.' },
                ],
                initialValue: '',
              })(
                <Input type="password" placeholder="Passwort" />,
              )}
            </Form.Item>

            <Form.Item>
              <Button block type="primary" htmlType="submit">Anmelden</Button>
            </Form.Item>
          </Form>
          <Link to="/change-password">
            Password vergessen / ändern
          </Link>
          {
            loginError
            && (
              <Alert
                type="error"
                message={(
                  <Typography>
                    Entweder die Kombination von E-Mail und Passwort ist ungültig,
                    oder Sie wurde keinem Projekt zugeordnet.
                    Versuchen Sie es erneut oder wenden Sie sich an&#160;
                    <a href="mailto:support@antwortING.de">support@antwortING.de</a>
                    .
                  </Typography>
                )}
              />
            )
          }
        </Card>
      )
  );
};

export default Form.create({ name: 'login' })(LoginForm);
