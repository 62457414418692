import React from 'react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { notification, Typography } from 'antd';
import { RcFile } from 'antd/es/upload';
import {
  APILocation,
  CreateForecast,
  CreateOperationData,
  CreateScenario,
  DataType,
  DBDetailLocationFromServer,
  DBLocation,
  DBLocationFromServer,
  DBScenarioFull,
  ForecastFromServerFull,
  LoginResponse,
  MeResponse,
  DBDetailLocation,
  UploadCSVResponse,
  DBForecastFull,
  OperationDataFromServerFull,
  ScenarioFromServerFull,
  WithId,
  WithType,
  FAQArticle,
  DBOperationDataFull,
  OperationDataComplete,
  AssessmentData,
  LocationType, PasswordResetResponse, PrintData,
} from './types';
import { apiUrl } from '../config';

const loginUrl = `${apiUrl}login/`;
const accountUrl = `${apiUrl}accounts/`;
const meUrl = `${accountUrl}me/`;
const changePasswordUrl = `${apiUrl}password/reset/`;
const passwordConfirmUrl = `${changePasswordUrl}confirm/`;
const locationUrl = `${apiUrl}locations/`;
const csvUpload = `${apiUrl}operationdata_import/`;
const articlesUrl = `${apiUrl}articles/`;
const assessmentUrl = `${apiUrl}assessment/`;
const printUrl = `${apiUrl}print/download/`;

const dataUrl = (type: DataType) => {
  switch (type) {
    case DataType.OperationData:
      return `${apiUrl}operationdata/`;
    case DataType.Forecast:
      return `${apiUrl}forecasts/`;
    case DataType.Scenario:
      return `${apiUrl}scenarios/`;
    default:
      return '';
  }
};

export const showGeneralError = () => {
  notification.error({
    message: (
      <Typography>
        Ein Fehler ist aufgetreten. Bitte wenden Sie sich an&#160;
        <a href="mailto:support@antwortING.de">support@antwortING.de</a>
        .
      </Typography>
    ),
    placement: 'bottomRight',
  });
};

export const meAPI: (axiosAuthConfig: AxiosRequestConfig) => Promise<MeResponse> = (
  axiosAuthConfig,
) => (
  axios.get<MeResponse>(meUrl, axiosAuthConfig)
    .then((response) => response.data)
);

/**
 * @description Send a login request to the backend and set appropriate cookies
 * on success.
 * @param email - Email of the user.
 * @param password - Password of the user.
 */
export const loginAPI: (email: string, password: string) => Promise<LoginResponse> = (
  email: string, password: string,
) => (
  axios.post<LoginResponse>(loginUrl, { email, password })
    .then((response) => response.data)
);

export const passwordResetAPI: (email: string) => Promise<PasswordResetResponse> = (
  email,
) => axios.post<PasswordResetResponse>(changePasswordUrl, { email })
  .then((response) => response.data);

export const passwordConfirmAPI: (
  newPassword1: string,
  newPassword2: string,
  uid: string,
  token: string,
) => Promise<PasswordResetResponse> = (
  newPassword1, newPassword2, uid, token,
) => (
  axios.post<PasswordResetResponse>(
    `${passwordConfirmUrl}${uid}/${token}/`, {
      newPassword1, newPassword2, uid, token,
    },
  ).then((response) => response.data)
);

function convertDBDetailLocation({
  operationDataList,
  forecastList,
  scenarioList,
  ...rest
}: DBDetailLocationFromServer): DBDetailLocation {
  return ({
    ...rest,
    operationDataList: operationDataList.map(
      (entry) => ({ ...entry, type: DataType.OperationData }),
    ),
    forecastList: forecastList.map(
      (entry) => ({ ...entry, type: DataType.Forecast }),
    ),
    scenarioList: scenarioList.map(
      (entry) => ({ ...entry, type: DataType.Scenario }),
    ),
  });
}

function convertDBLocation({
  operationDataList,
  forecastList,
  scenarioList,
  ...rest
}: DBLocationFromServer): DBLocation {
  return ({
    ...rest,
    operationDataList: operationDataList.map(
      (entry) => ({ ...entry, type: DataType.OperationData }),
    ),
    forecastList: forecastList.map(
      (entry) => ({ ...entry, type: DataType.Forecast }),
    ),
    scenarioList: scenarioList.map(
      (entry) => ({ ...entry, type: DataType.Scenario }),
    ),
  });
}

/**
 * @description Send the description of a new location to the backend.
 * @param location - An APILocation object to be stored in the db.
 * @param axiosAuthConfig - The axios config object obtained from UserStore.
 */
export const createLocationAPI: (
  location: APILocation,
  axiosAuthConfig: AxiosRequestConfig
) => Promise<DBLocation> = (
  location, axiosAuthConfig,
) => axios.post<DBLocationFromServer>(locationUrl, { ...location }, axiosAuthConfig)
  .then((response) => convertDBLocation(response.data));


/**
 * @description Send the description of a new location to the backend.
 * @param location - Location to be stored in the db.
 * @param axiosAuthConfig - The axios config object obtained from UserStore.
 */
export const editLocationAPI: (
  location: DBLocation,
  axiosAuthConfig: AxiosRequestConfig,
) => Promise<DBLocation> = (
  location, axiosAuthConfig,
) => axios.put<DBLocationFromServer>(`${locationUrl}${location.id}/`, { ...location }, axiosAuthConfig)
  .then((response) => convertDBLocation(response.data));

export const deleteLocationAPI: (
  id: number,
  axiosAuthConfig: AxiosRequestConfig,
) => Promise<AxiosResponse> = (
  id, axiosAuthConfig,
) => axios.delete(`${locationUrl}${id}`, axiosAuthConfig);

export const getLocationListAPI: (
  axiosAuthConfig: AxiosRequestConfig
) => Promise<DBLocation[]> = (
  axiosAuthConfig,
) => axios.get<DBLocationFromServer[]>(locationUrl, axiosAuthConfig)
  .then((response) => response.data.map(convertDBLocation));

export const getLocationDetailAPI: (
  id: number,
  axiosAuthConfig: AxiosRequestConfig,
) => Promise<DBDetailLocation> = (
  id, axiosAuthConfig,
) => axios.get<DBDetailLocationFromServer>(`${locationUrl}${id}/`, axiosAuthConfig)
  .then((response) => convertDBDetailLocation(response.data));

export const createOperationDataAPI: (
  data: CreateOperationData,
  axiosAuthConfig: AxiosRequestConfig,
) => Promise<DBOperationDataFull> = (
  data, axiosAuthConfig,
) => axios.post<OperationDataFromServerFull>(
  dataUrl(DataType.OperationData), data, axiosAuthConfig,
).then((response) => ({
  ...response.data, type: DataType.OperationData,
}));

export const createForecastAPI: (
  data: CreateForecast,
  axiosAuthConfig: AxiosRequestConfig,
) => Promise<DBForecastFull> = (
  data, axiosAuthConfig,
) => axios.post<ForecastFromServerFull>(
  dataUrl(DataType.Forecast), data, axiosAuthConfig,
).then((response) => ({
  ...response.data, type: DataType.Forecast,
}));

export const createScenarioAPI: (
  data: CreateScenario,
  axiosAuthConfig: AxiosRequestConfig,
) => Promise<DBScenarioFull> = (
  data, axiosAuthConfig,
) => axios.post<ScenarioFromServerFull>(
  dataUrl(DataType.Scenario), data, axiosAuthConfig,
).then((response) => ({
  ...response.data, type: DataType.Scenario,
}));

export const updateOperationDataAPI: (
  data: DBOperationDataFull,
  axiosAuthConfig: AxiosRequestConfig,
) => Promise<DBOperationDataFull> = (
  data, axiosAuthConfig: AxiosRequestConfig,
) => axios.put<OperationDataFromServerFull>(
  `${dataUrl(data.type)}${data.id}/`, data, axiosAuthConfig,
).then((response) => ({
  ...response.data, type: DataType.OperationData,
}));

export const updateScenarioAPI: (
  data: DBScenarioFull,
  axiosAuthConfig: AxiosRequestConfig,
) => Promise<DBScenarioFull> = (
  data, axiosAuthConfig: AxiosRequestConfig,
) => axios.put<ScenarioFromServerFull>(
  `${dataUrl(data.type)}${data.id}/`, data, axiosAuthConfig,
).then((response) => ({
  ...response.data, type: DataType.Scenario,
}));

export const deleteDataAPI: (
  data: WithType & WithId,
  axiosAuthConfig: AxiosRequestConfig,
) => Promise<AxiosResponse> = (
  data, axiosWithAuth,
) => axios.delete(`${dataUrl(data.type)}${data.id}/`, axiosWithAuth);

export const uploadCsvAPI: (
  file: RcFile,
  locationId: number,
  axiosAuthConfig: AxiosRequestConfig,
) => Promise<UploadCSVResponse> = (
  file, locationId, axiosAuthConfig,
) => {
  const formData = new FormData();
  formData.append('import_file', file);
  formData.append('location', `${locationId}`);

  return axios.post<UploadCSVResponse>(csvUpload, formData, {
    headers: {
      ...axiosAuthConfig.headers,
      'Content-Type': 'multipart/form-data',
    },
  }).then((response) => response.data);
};

export const getFAQArticlesListAPI: (
  axiosAuthConfig: AxiosRequestConfig
) => Promise<FAQArticle[]> = (
  axiosAuthConfig,
) => axios.get(articlesUrl, axiosAuthConfig)
  .then((response) => response.data);

export const createAssessmentAPI: (
  operationData: OperationDataComplete,
  year: number,
  locationType: LocationType,
  axiosAuthConfig: AxiosRequestConfig,
) => Promise<AssessmentData> = (
  operationData, year, locationType, axiosAuthConfig,
) => axios.post<{ serviceLevel: AssessmentData }>(
  assessmentUrl, { data: { ...operationData }, year, locationType }, axiosAuthConfig,
).then((response) => (response.data.serviceLevel));

export const downloadPrintedAssessmentAPI: (
  data: PrintData,
  axiosAuthConfig: AxiosRequestConfig,
) => Promise<void> = (
  data,
  axiosWithAuth,
) => axios({
  method: 'post',
  responseType: 'blob',
  url: printUrl,
  data: data.data,
  ...axiosWithAuth,
}).then((response) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    data.filename,
  ); // or any other extension
  document.body.append(link);
  link.click();
});
