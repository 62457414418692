import React, {
  createRef, FC, useContext, useState,
} from 'react';
import {
  Alert, Modal, notification, Spin,
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { AxiosError } from 'axios';
import { ActiveLocationContext } from '../../context/ActiveLocationStore';
import SaveScenarioForm from './SaveScenarioForm';
import { OperationDataContext } from '../../context/OperationDataStore';
import { DataType, DBScenarioFull, OperationDataComplete } from '../../api/types';

interface SaveScenarioModalProps {
  showModal: boolean;
  afterClose: (newScenario?: DBScenarioFull) => void;
}

/**
 * @description Open a toast to inform the user the forecast was saved.
 */
const openSuccessfulSaveNotification = (): void => {
  notification.success({
    message: 'Szenario erfolgreich gespeichert!',
    placement: 'bottomRight',
  });
};

const SaveScenarioModal: FC<SaveScenarioModalProps> = ({
  showModal, afterClose,
}) => {
  const formRef = createRef<FormComponentProps>();
  const [formValidationError, setFormValidationError] = useState<string>('');
  const [showModalSpin, setShowModalSpin] = useState(false);
  const { createOrUpdateScenario } = useContext(ActiveLocationContext);
  const { initialValues, operationData } = useContext(OperationDataContext);

  const closeAndResetModal = (newScenario?: DBScenarioFull): void => {
    if (formRef && formRef.current) {
      formRef.current.form.resetFields();
    }
    setFormValidationError('');
    setShowModalSpin(false);
    afterClose(newScenario);
  };

  const handleError = (error: AxiosError): void => {
    const { response } = error;
    if (response && response.status === 400) {
      let errorMessage = '';
      Object.keys(response.data).forEach((validationError) => {
        errorMessage += `${response.data[validationError]}\n`;
      });
      setFormValidationError(errorMessage);
    }
  };

  const handleSave = (): void => {
    if (formRef && formRef.current && initialValues) {
      const { form } = formRef.current;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      form.validateFields(async (err: any, values: {name: string; description?: string}) => {
        if (!err) {
          setShowModalSpin(true);
          createOrUpdateScenario(initialValues.type === DataType.Scenario
            ? {
              ...(initialValues as DBScenarioFull),
              ...values,
              // The save button is only enabled, when the data set is complete.
              // Therefore we can cast it to a full data set at this point
              data: operationData as OperationDataComplete,
            } : {
              location: initialValues.location,
              year: initialValues.year,
              ...values,
              data: operationData as OperationDataComplete,
            }).then((result) => {
            form.resetFields();
            closeAndResetModal(result);
            openSuccessfulSaveNotification();
          }).catch((error: AxiosError) => {
            setShowModalSpin(false);
            handleError(error);
          });
        }
      });
    }
  };

  return (
    <Modal
      cancelText="Abbrechen"
      okText="Speichern"
      onCancel={() => closeAndResetModal()}
      onOk={() => handleSave()}
      title="Szenario speichern"
      visible={showModal}
    >
      <Spin spinning={showModalSpin}>
        {initialValues?.type === DataType.Scenario
          && <Alert type="warning" message="Warnung: Szenariodaten werden überschrieben!" />}
        <SaveScenarioForm
          wrappedComponentRef={formRef}
        />
        {
          formValidationError !== '' && (
            <Alert
              type="error"
              message={formValidationError}
            />
          )
        }
      </Spin>
    </Modal>
  );
};

export default SaveScenarioModal;
