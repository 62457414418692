import React, { FC, useContext } from 'react';
import {
  Button, Layout, Modal, Typography,
} from 'antd';
import { UserContext } from '../../context/UserStore';
import './Navigation.scss';
import { ActiveLocationContext } from '../../context/ActiveLocationStore';
import { NavLink } from './NavLink';
import { passwordResetAPI } from '../../api';

export const Navigation: FC = () => {
  const { user } = useContext(UserContext);
  const { activeLocation, opened } = useContext(ActiveLocationContext);

  if (user === undefined) return <div className="navigation--no-user" />;

  return (
    <Layout.Sider className="navigation" width={350}>
      <div className="navigation__children">
        <img
          className="navigation__logo"
          alt="Logo"
          src="/assets/navigation/antworting_prognosetool_neg_3x.png"
        />

        <div className="navigation__info">
          <Typography.Paragraph>
            <strong>lizensiert für</strong>
            <br />
            {user.projectName}
          </Typography.Paragraph>

          <Typography.Paragraph>
            <strong>angemeldet</strong>
            <br />
            {user.displayName}
          </Typography.Paragraph>
        </div>

        <div className="navigation__link">
          <NavLink to="/" id="navigation-dashboard">Standorte</NavLink>
        </div>

        { activeLocation && opened && (
          <div className="navigation__collapsible">
            <div className="navigation__link">
              <NavLink
                to={`/location/${activeLocation.id}`}
                id="navigation-location-detail"
              >
                Einsatzdaten
              </NavLink>
            </div>

            <div className="navigation__link">
              <NavLink
                to={`/location/forecasts/${activeLocation.id}`}
                id="navigation-forecasts"
              >
                Entwicklung & Prognose
              </NavLink>
            </div>

            <div className="navigation__link">
              <NavLink
                to={`/location/scenario/${activeLocation.id}`}
                id="navigation-scenario"
              >
                Planung & Szenario
              </NavLink>
            </div>
          </div>
        )}

        <div className="navigation__link">
          <NavLink to="/faq" id="navigation-faq">FAQ</NavLink>
        </div>

        <div className="navigation__link navigation__change-password">
          <Button
            type="link"
            className="navigation__change-password__button"
            id="navigation-change-password"
            onClick={() => passwordResetAPI(user.email)
              .then(() => Modal.success({
                content: 'Eine E-Mail mit einem Link zum Zurücksetzen Ihres Passwortes wurde versandt.',
              }))}
          >
            Passwort ändern
          </Button>
        </div>

        <div className="navigation__link">
          <NavLink to="/logout" id="navigation-logout">Abmelden</NavLink>
        </div>
      </div>
    </Layout.Sider>
  );
};
