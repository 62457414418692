import React, {
  FC, useContext, useEffect, useState,
} from 'react';
import {
  Alert, Card, Spin, Tabs,
} from 'antd';
import { AssessmentData, WeekdayType } from '../../api/types';
import { AssessmentGraph } from './AssessmentGraph';

import './AssessmentWrapper.scss';
import { OperationDataContext } from '../../context/OperationDataStore';
import { createAssessmentAPI } from '../../api';
import { UserContext } from '../../context/UserStore';
import { ActiveLocationContext } from '../../context/ActiveLocationStore';
import { OperationDataIncomplete } from '../../context/types';

interface TabDescriptor {
  name: WeekdayType;
}

const hasValues = (data: OperationDataIncomplete) => Object.values(data).filter((array) => (
  array.filter((value: number|undefined) => value !== undefined).length > 0
)).length > 0;

const removeUndefined = (array: (number|undefined)[]) => (
  array.map((entry) => entry || 0)
);

export const AssessmentWrapper: FC = () => {
  const [assessmentData, setAssessmentData] = useState<AssessmentData|undefined>(undefined);
  const [showSpin, setShowSpin] = useState(false);
  const { initialValues, operationData } = useContext(OperationDataContext);
  const { axiosAuthConfig } = useContext(UserContext);
  const { activeLocation } = useContext(ActiveLocationContext);

  useEffect(() => {
    if (activeLocation?.locationType && initialValues && hasValues(operationData)) {
      setShowSpin(true);
      createAssessmentAPI(
        {
          MoFrDurations: removeUndefined(operationData.MoFrDurations),
          MoFrIncidents: removeUndefined(operationData.MoFrIncidents),
          SaSuDurations: removeUndefined(operationData.SaSuDurations),
          SaSuIncidents: removeUndefined(operationData.SaSuIncidents),
        },
        initialValues.year,
        activeLocation.locationType,
        axiosAuthConfig,
      )
        .then((response) => {
          setAssessmentData(response);
          setShowSpin(false);
        });
    } else {
      setAssessmentData(undefined);
      setShowSpin(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLocation, operationData]);

  const tabs: TabDescriptor[] = [
    {
      name: WeekdayType.BusinessDay,
    },
    {
      name: WeekdayType.Weekend,
    },
  ];

  const makeTab = (tab: TabDescriptor) => {
    if (assessmentData && activeLocation) {
      return (
        <Tabs.TabPane tab={tab.name} key={tab.name} className="operation-form__tab">
          <AssessmentGraph
            locationType={activeLocation.locationType}
            serviceLevelLower={
              tab.name === WeekdayType.BusinessDay
                ? assessmentData.moFrServiceLevelA : assessmentData.saSuServiceLevelA
            }
            serviceLevelUpper={
              tab.name === WeekdayType.BusinessDay
                ? assessmentData.moFrServiceLevelB : assessmentData.saSuServiceLevelB
            }
          />
        </Tabs.TabPane>
      );
    }

    return ({});
  };

  return (
    <Card className="assessment-wrapper">
      <Spin spinning={showSpin} className="centered-spin">
        { assessmentData && activeLocation ? (
          <Tabs defaultActiveKey={WeekdayType.BusinessDay}>
            {
              tabs.map(makeTab)
            }
          </Tabs>
        ) : (
          <Alert message="Bitte haben Sie einen Augenblick geduld." type="warning" />
        )}
      </Spin>
    </Card>
  );
};
