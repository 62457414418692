import React, {
  createContext, FC, useContext, useState,
} from 'react';
import {
  APIData, CreateForecast, CreateOperationData,
  DataType, WithData,
  DBDetailLocation, CreateScenario,
  DBForecastFull, DBOperationDataFull, DBScenarioFull, WithId, WithType,
} from '../api/types';
import {
  createOperationDataAPI,
  createForecastAPI,
  createScenarioAPI,
  deleteDataAPI,
  getLocationDetailAPI,
  updateOperationDataAPI,
  updateScenarioAPI,
} from '../api';
import { LocationContext } from './LocationStore';
import { UserContext } from './UserStore';
import { ActiveLocationStoreValue } from './types';

const errorExecutor = () => {
  throw new Error('no active store available');
};

const nullExecutor = () => null;

export const ActiveLocationContext = createContext<ActiveLocationStoreValue>({
  opened: false,
  setActiveLocation: () => new Promise<DBDetailLocation>(errorExecutor),
  reloadActiveLocation: () => new Promise<DBDetailLocation>(errorExecutor),
  closeActiveLocation: nullExecutor,
  createOrUpdateOperationData: () => new Promise<DBOperationDataFull>(errorExecutor),
  createForecast: () => new Promise<DBForecastFull>(errorExecutor),
  createOrUpdateScenario: () => new Promise<DBScenarioFull>(errorExecutor),
  deleteData: () => new Promise<void>(errorExecutor),
});

const replacer = <T extends DBOperationDataFull|DBScenarioFull>(result: T) => (
  <U extends DBOperationDataFull|DBScenarioFull>(entry: U) => (
    entry.type === result.type && entry.id === result.id ? result : entry
  ) as U
);

const deleter = ({ type, id }: WithType & WithId) => (
  (entry: WithType & WithId) => entry.type !== type || entry.id !== id
);

export const ActiveLocationStore: FC = ({ children }) => {
  const [opened, setOpened] = useState(0);
  const [activeLocation, setActiveLocation] = useState<DBDetailLocation>();

  const { axiosAuthConfig } = useContext(UserContext);
  const { createOrUpdateLocation } = useContext(LocationContext);

  const setActiveLocationWithId = (id: number) => {
    setOpened((o) => o + 1);
    if (id !== activeLocation?.id) {
      return getLocationDetailAPI(id, axiosAuthConfig).then((location) => {
        setActiveLocation(location);

        return location;
      });
    }

    return new Promise<DBDetailLocation>(() => activeLocation);
  };

  const reloadActiveLocation = () => {
    if (!activeLocation) {
      return new Promise<DBDetailLocation>(
        () => { throw new Error('no active location to reset'); },
      );
    }

    return getLocationDetailAPI(
      activeLocation.id, axiosAuthConfig,
    ).then((location) => {
      setActiveLocation(location);

      return location;
    });
  };

  const unsetActiveLocation: () => void = () => {
    setOpened((o) => o - 1);
  };

  const deleteData = (
    data: WithId & WithType,
  ) => deleteDataAPI(data, axiosAuthConfig)
    .then(() => {
      if (activeLocation) {
        const newActiveLocation: DBDetailLocation = ({
          ...activeLocation,
          operationDataList: activeLocation.operationDataList.filter(deleter(data)),
          forecastList: activeLocation.forecastList.filter(deleter(data)),
          scenarioList: activeLocation.scenarioList.filter(deleter(data)),
        });
        setActiveLocation(newActiveLocation);
        createOrUpdateLocation(newActiveLocation, true);
      }
    });

  const addCreatedResultToStore = <T extends APIData>(
    result: T & WithData & WithType,
  ) => {
    if (!activeLocation) throw new Error('data created without active location');
    const newActiveLocation: DBDetailLocation = ({
      ...activeLocation,
      [result.type]: [result, ...activeLocation[result.type as DataType]],
    });
    setActiveLocation(newActiveLocation);
    createOrUpdateLocation(newActiveLocation, true);

    return result;
  };

  const mergeUpdatedResultIntoStore = <T extends DBScenarioFull|DBOperationDataFull>(
    result: T,
  ) => {
    if (!activeLocation) throw new Error('data updated without active location');
    const newActiveLocation: DBDetailLocation = ({
      ...activeLocation,
      operationDataList: activeLocation.operationDataList.map(replacer(result)),
      scenarioList: activeLocation.scenarioList.map(replacer(result)),
    });
    setActiveLocation(newActiveLocation);
    createOrUpdateLocation(newActiveLocation, true);

    return result;
  };

  const createOrUpdateOperationData: (
    data: CreateOperationData|DBOperationDataFull
  ) => Promise<DBOperationDataFull> = (
    data,
  ) => {
    if (!('id' in data) || data.id === undefined) {
      return createOperationDataAPI(data, axiosAuthConfig)
        .then(addCreatedResultToStore);
    }

    return updateOperationDataAPI(data, axiosAuthConfig)
      .then(mergeUpdatedResultIntoStore);
  };

  const createForecast: (data: CreateForecast) => Promise<DBForecastFull> = (
    data,
  ) => createForecastAPI(data, axiosAuthConfig)
    .then(addCreatedResultToStore);

  const createOrUpdateScenario: (
    data: CreateScenario|DBScenarioFull
  ) => Promise<DBScenarioFull> = (
    data,
  ) => {
    if (!('id' in data) || data.id === undefined) {
      return createScenarioAPI(data, axiosAuthConfig)
        .then(addCreatedResultToStore);
    }

    return updateScenarioAPI(data, axiosAuthConfig)
      .then(mergeUpdatedResultIntoStore);
  };

  return (
    <ActiveLocationContext.Provider
      value={{
        opened: opened > 0,
        activeLocation,
        setActiveLocation: setActiveLocationWithId,
        reloadActiveLocation,
        closeActiveLocation: unsetActiveLocation,
        createOrUpdateOperationData,
        createForecast,
        createOrUpdateScenario,
        deleteData,
      }}
    >
      {children}
    </ActiveLocationContext.Provider>
  );
};
