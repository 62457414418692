import React, {
  forwardRef, useImperativeHandle,
} from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { Form, Input, Select } from 'antd';

const ForcastForm = forwardRef<FormComponentProps, FormComponentProps>(
  (
    { form },
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      form,
    }));
    const { getFieldDecorator } = form;
    const currentYear = (new Date()).getFullYear();
    const nextYear = currentYear + 1;


    return (
      <Form layout="vertical">
        <Form.Item label="Bezeichnung des Prognoses">
          {getFieldDecorator('name', {
            rules: [
              { required: true, message: 'Bitte geben Sie eine Bezeichnung ein' },
            ],
          })(
            <Input type="text" placeholder="Prognose 2020" />,
          )}
        </Form.Item>

        <Form.Item label="Beschreibung">
          {getFieldDecorator('description')(
            <Input.TextArea rows={3} />,
          )}
        </Form.Item>

        <Form.Item label="Jahr">
          {getFieldDecorator('year', {
            rules: [
              { required: true, message: 'Bitte wählen Sie ein Jahr aus' },
            ],
          })(
            <Select>
              <Select.Option value={currentYear}>
                {currentYear}
              </Select.Option>
              <Select.Option value={nextYear}>
                {nextYear}
              </Select.Option>
            </Select>,
          )}
        </Form.Item>
      </Form>
    );
  },
);

export default Form.create({ name: 'forecastForm' })(ForcastForm);
