import React, { FC, useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Spin } from 'antd';
import { UserContext } from '../context/UserStore';

interface Props extends RouteProps {
  path: string;
}

const Spinner: FC = () => (<Spin className="centered-spinner" />);
const RedirectToLogin: FC = () => (<Redirect to="/login" />);

export const ProtectedRoute: FC<Props> = ({
  path,
  component,
  render,
  children,
  ...rest
}) => {
  const { user, loading } = useContext(UserContext);

  if (loading) return <Route {...rest} path={path} component={Spinner} />;
  if (!user) return <Route {...rest} path={path} component={RedirectToLogin} />;

  return <Route {...rest} path={path} component={component} render={render}>{children}</Route>;
};
