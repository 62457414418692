import React, {
  FC, createContext, useState, useEffect, useContext,
} from 'react';
import { APILocation, DBLocation } from '../api/types';
import {
  editLocationAPI,
  deleteLocationAPI,
  getLocationListAPI,
  createLocationAPI,
} from '../api';
import { UserContext } from './UserStore';
import { LocationStoreValue } from './types';

const errorExecutor = () => {
  throw new Error('no location store available');
};

export const LocationContext = createContext<LocationStoreValue>({
  deleteLocation: () => new Promise<void>(errorExecutor),
  createOrUpdateLocation: () => new Promise<DBLocation>(errorExecutor),
});

export const LocationStore: FC = ({ children }) => {
  const [locations, setLocations] = useState<DBLocation[]|undefined>(undefined);

  const { user, axiosAuthConfig } = useContext(UserContext);

  useEffect(() => {
    getLocationListAPI(axiosAuthConfig).then((locationList) => setLocations(locationList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]); // Reload whenever the user object changes

  const addNewLocation = (location: DBLocation) => {
    setLocations(locations ? [location, ...locations] : [location]);

    return location;
  };

  const updateLocation = (location: DBLocation) => {
    // map old locations to new locations, except for the one,
    // which has the same id as our new location.
    setLocations(locations?.map((oldLocation) => (
      oldLocation.id === location.id ? location : oldLocation
    )));

    return location;
  };

  const createOrUpdateLocation = (
    location: APILocation,
    updateOnlyLocal?: boolean,
  ): Promise<DBLocation> => {
    if (!location.id) {
      return createLocationAPI(location, axiosAuthConfig)
        .then(addNewLocation);
    }

    return (updateOnlyLocal
      ? new Promise<DBLocation>(() => location)
      : editLocationAPI(location as DBLocation, axiosAuthConfig))
      .then(updateLocation);
  };

  const deleteLocation: (id: number) => Promise<void> = (id) => (
    deleteLocationAPI(id, axiosAuthConfig)
      .then(() => {
        if (locations) {
          const newLocations = locations.filter((loc) => loc.id !== id);

          if (locations.length > newLocations.length) {
            setLocations(newLocations);
          }
        }
      })
  );

  return (
    <LocationContext.Provider value={{
      locationList: locations,
      createOrUpdateLocation,
      deleteLocation,
    }}
    >
      {children}
    </LocationContext.Provider>
  );
};
