import React, {
  createContext, forwardRef, useContext, useImperativeHandle,
} from 'react';
import {
  Col, Form, Input, Row, Select,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { APILocation, LocationType } from '../../api/types';


export const EditLocationContext = createContext<APILocation|undefined>(undefined);

const LocationModalForm = forwardRef<FormComponentProps, FormComponentProps>(
  (
    { form },
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      form,
    }));
    const { getFieldDecorator } = form;

    const initialValues = useContext(EditLocationContext);

    return (
      <Form layout="vertical">
        <Form.Item label="Bezeichnung des Standorts">
          {getFieldDecorator('name', {
            initialValue: initialValues ? initialValues.name : '',
            rules: [
              { required: true, message: 'Bitte geben Sie eine Bezeichnung ein' },
            ],
          })(
            <Input type="text" placeholder="Rettungswache X" />,
          )}
        </Form.Item>

        <Form.Item label="Standort-Typ">
          {getFieldDecorator('locationType', {
            initialValue: initialValues ? initialValues.locationType : '',
            rules: [
              { required: true, message: 'Bitte wählen Sie einen Standort-Typ aus' },
            ],
          })(
            <Select>
              <Select.Option value={LocationType.NEF}>{LocationType.NEF}</Select.Option>
              <Select.Option value={LocationType.RTW}>{LocationType.RTW}</Select.Option>
            </Select>,
          )}
        </Form.Item>

        <Row gutter={48}>
          <Col span={14}>
            <Form.Item label="Straße">
              {getFieldDecorator('street',
                { initialValue: initialValues ? initialValues.street : '' })(
                  <Input type="text" placeholder="Musterstraße" />,
              )}
            </Form.Item>
          </Col>

          <Col span={10}>
            <Form.Item label="Hausnummer">
              {getFieldDecorator('houseNumber',
                { initialValue: initialValues ? initialValues.houseNumber : '' })(
                  <Input type="text" placeholder="42" />,
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={48}>
          <Col span={6}>
            <Form.Item label="Postleitzahl">
              {getFieldDecorator('plz',
                { initialValue: initialValues ? initialValues.plz : '' })(
                  <Input type="text" placeholder="12345" />,
              )}
            </Form.Item>
          </Col>

          <Col span={18}>
            <Form.Item label="Ort">
              {getFieldDecorator('location',
                { initialValue: initialValues ? initialValues.location : '' })(
                  <Input type="text" placeholder="Musterstadt" />,
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={48}>
          <Col span={12}>
            <Form.Item label="Östliche Länge (Dezimalgrad WGS84)">
              {getFieldDecorator('longitude',
                { initialValue: initialValues ? initialValues.longitude : '' })(
                  <Input type="text" placeholder="51,161858" />,
              )}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Nördliche Breite (Dezimalgrad WGS84)">
              {getFieldDecorator('latitude',
                { initialValue: initialValues ? initialValues.latitude : '' })(
                  <Input type="text" placeholder="10,3349292" />,
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  },
);

export default Form.create({ name: 'locationForm' })(LocationModalForm);
