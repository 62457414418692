import React, {
  forwardRef, Fragment, useContext, useEffect, useImperativeHandle, useState,
} from 'react';
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Slider,
  Spin,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import {
  DataType, OperationDataType,
} from '../../api/types';
import { OperationDataContext } from '../../context/OperationDataStore';

import './OperationForm.scss';
import { OperationDataIncomplete } from '../../context/types';
import { GraphType, Linegraph } from '../Linegraph/Linegraph';
import { UserContext } from '../../context/UserStore';
import { downloadPrintedAssessmentAPI } from '../../api';
import { ActiveLocationContext } from '../../context/ActiveLocationStore';

interface DataDescriptor {
  key: number;
  title: string;
  operationDataType: OperationDataType;
}

interface TabDescriptor {
  tabHeader: string;
  sliderValue: number;
  setSlider: (value: number|number[]) => void;
  name: GraphType;
  content: DataDescriptor[];
}

const range24 = [...new Array(24)].map((_, index) => index);

const round = (value: number, dataType: OperationDataType) => (
  dataType === OperationDataType.SaSuIncidents
  || dataType === OperationDataType.MoFrIncidents
    ? Math.round(value)
    : Math.round(value * 10) / 10
);

const entriesMissing: (operationData: OperationDataIncomplete) => boolean = (
  operationData,
) => (
  Object.values(operationData).filter(
    (row: (number|undefined)[]) => row.filter(
      (entry) => entry === undefined,
    ).length > 0,
  ).length > 0
);

const OperationForm = forwardRef<FormComponentProps, FormComponentProps>(
  (
    { form },
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      form,
    }));
    const rowGutter = 10;
    const leftColumnSpan = 2;
    const rightColumnSpan = 22;

    const {
      getFieldDecorator,
      setFieldsValue,
      resetFields,
    } = form;
    const {
      initialValues,
      allowedYearsToSelect,
      readOnlyMode,
      submitCallbacks,
      operationData,
      setOperationData,
      originalOperationData,
      resetOperationData,
    } = useContext(OperationDataContext);
    const { axiosAuthConfig } = useContext(UserContext);
    const { activeLocation } = useContext(ActiveLocationContext);
    const [sliderDurations, setSliderDurations] = useState(0);
    const [sliderIncidents, setSliderIncidents] = useState(0);

    const saveableChanges: boolean = (!originalOperationData
              && !entriesMissing(operationData));

    const reset = () => {
      setSliderIncidents(0);
      setSliderDurations(0);
      resetFields();
      resetOperationData();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(reset, [initialValues]);

    const getSlider = (dataType: OperationDataType) => (
      dataType === OperationDataType.MoFrDurations
      || dataType === OperationDataType.SaSuDurations
        ? sliderDurations
        : sliderIncidents
    );

    const handleSliderChange = (
      sliderSetter: (value: number) => void,
      dataTypes: OperationDataType[],
      sliderValue: number,
    ) => {
      const factor = (1 + 0.01 * sliderValue) / (1 + 0.01 * getSlider(dataTypes[0]));
      const newOperationData = { ...operationData };
      const fieldsData: { [key: string]: (number|undefined)[] } = {};

      dataTypes.forEach((dataType) => {
        newOperationData[dataType] = operationData[dataType].map(
          (value) => (value ? value * factor : undefined),
        );
        fieldsData[`data.${dataType}`] = newOperationData[dataType].map(
          (value) => (value ? round(value, dataType) : undefined),
        );
      });
      setOperationData(newOperationData);
      setFieldsValue(fieldsData);
      sliderSetter(sliderValue);
    };

    const handleIncidentsSlider = (value: number|number[]) => {
      handleSliderChange(
        setSliderIncidents,
        [OperationDataType.MoFrIncidents, OperationDataType.SaSuIncidents],
        value as number,
      );
    };

    const handleDurationsSlider = (value: number|number[]) => {
      handleSliderChange(
        setSliderDurations,
        [OperationDataType.MoFrDurations, OperationDataType.SaSuDurations],
        value as number,
      );
    };

    const handleChangeData = (
      value: number|undefined, index: number, dataType: OperationDataType,
    ) => {
      const data = { ...operationData };
      data[dataType] = operationData[dataType].map(
        (oldValue, i) => (i === index ? value : oldValue),
      );
      setOperationData(data);
    };

    const createFields = (idPrefix: OperationDataType) => {
      const minValue = (
        idPrefix === OperationDataType.MoFrDurations || idPrefix === OperationDataType.SaSuDurations
      ) ? 0.1 : 0;

      return (
        <Row gutter={[5, 0]}>
          {range24.map((index) => (
            <Col span={1} key={`${idPrefix}${index}`}>
              <Form.Item>
                {getFieldDecorator(`data.${idPrefix}[${index}]`, {
                  rules: [
                    { required: true },
                  ],
                  initialValue: initialValues ? (round(initialValues.data[idPrefix][index], idPrefix)) : '',
                })(
                  <InputNumber
                    className="operation-form__input"
                    type="text"
                    placeholder=""
                    onChange={(value) => handleChangeData(
                      Number(value) || undefined, index, idPrefix,
                    )}
                    min={minValue}
                    disabled={readOnlyMode}
                    decimalSeparator=","
                  />,
                )}
              </Form.Item>
            </Col>
          ))}
        </Row>
      );
    };

    const yearSelect = () => {
      if (submitCallbacks) return '';

      return (
        <Form.Item>
          {getFieldDecorator('year', {
            rules: [
              { required: true },
            ],
            initialValue: initialValues?.year,
          })(
            <Select
              style={{ minWidth: '125px' }}
              disabled={(!!(initialValues) || readOnlyMode)}
            >
              {
                initialValues?.year ? (
                  <Select.Option value={initialValues.year}>{initialValues.year}</Select.Option>
                ) : allowedYearsToSelect?.map((year: number) => (
                  <Select.Option key={year} value={year}>{year}</Select.Option>
                ))
              }
            </Select>,
          )}
        </Form.Item>
      );
    };

    const getFileName = () => {
      if (!initialValues || !activeLocation) return 'Bemessung.pdf';
      switch (initialValues.type) {
        case DataType.OperationData:
          return `Einsatzdaten ${initialValues.year} ${activeLocation.name}.pdf`;
        case DataType.Forecast:
          return `Prognose ${initialValues.year} ${initialValues.name} ${activeLocation.name}.pdf`;
        case DataType.Scenario:
          return `Szenario ${initialValues.name} ${activeLocation.name}.pdf`;
        default:
          return 'Bemessung.pdf';
      }
    };

    const controlButtons = () => {
      if (!submitCallbacks) return '';

      return (
        <span>
          <Tooltip title={`Als PDF herunterladen${
            !originalOperationData ? ' (Bitte Daten vorher speichern)' : ''
          }`}
          >
            <Button
              type="link"
              icon="printer"
              disabled={!originalOperationData}
              onClick={() => {
                if (!initialValues || !activeLocation) return;
                const loadingModal = Modal.info({
                  content: <Spin
                    className="operation-form__print-spin"
                    tip="PDF wird erzeugt. Dies kann bis zu einer Minute dauern. Zeit für einen Kaffee."
                  />,
                  okButtonProps: { hidden: true },
                  icon: null,
                });
                downloadPrintedAssessmentAPI({
                  data: initialValues,
                  filename: getFileName().replace(' ', '_'),
                }, axiosAuthConfig)
                  .finally(loadingModal.destroy);
              }}
            />
          </Tooltip>

          <Tooltip title="Löschen">
            <Button
              disabled={initialValues?.type !== DataType.Scenario}
              type="link"
              onClick={submitCallbacks.onDelete}
              icon="delete"
            />
          </Tooltip>

          <Tooltip title="Zurücksetzen">
            <Button
              disabled={originalOperationData}
              type="link"
              onClick={reset}
              icon="reload"
            />
          </Tooltip>

          <Tooltip title="Speichern">
            <Button
              disabled={!saveableChanges}
              type="link"
              onClick={submitCallbacks.onSave}
              icon="save"
            />
          </Tooltip>
        </span>
      );
    };

    const makeTabExtras = () => (
      <div>
        {yearSelect()}
        {controlButtons()}
      </div>
    );

    const createLegend = () => {
      const hours = range24.map((index) => (
        <Col span={1} key={index}>
          <Typography.Text>{index}</Typography.Text>
        </Col>
      ));

      return (
        <Row gutter={0} className="operation-form__legend">
          {hours}
        </Row>
      );
    };

    const tabs: TabDescriptor[] = [
      {
        tabHeader: 'Einsatzhäufigkeiten',
        sliderValue: sliderIncidents,
        setSlider: handleIncidentsSlider,
        name: 'incidents',
        content: [
          {
            key: 1,
            title: 'Mo-Fr',
            operationDataType: OperationDataType.MoFrIncidents,
          },
          {
            key: 2,
            title: 'Sa-So',
            operationDataType: OperationDataType.SaSuIncidents,
          },
        ],
      },
      {
        tabHeader: 'Einsatzdauer',
        sliderValue: sliderDurations,
        setSlider: handleDurationsSlider,
        name: 'durations',
        content: [
          {
            key: 1,
            title: 'Mo-Fr',
            operationDataType: OperationDataType.MoFrDurations,
          },
          {
            key: 2,
            title: 'Sa-So',
            operationDataType: OperationDataType.SaSuDurations,
          },
        ],
      },
    ];

    const makeSlider = (tab: TabDescriptor) => {
      if (!submitCallbacks) return '';

      return (
        <div className="operation-form__slider">
          <Slider
            // Wird gebraucht um die Slider zurückzusetzen,
            // wenn ein neuer Datasatz (initialValues) geladen
            // wird.
            key={tab.sliderValue}
            defaultValue={tab.sliderValue}
            onAfterChange={tab.setSlider}
            included={false}
            min={-20}
            max={20}
            marks={{
              0: '0%',
              20: '+20%',
              '-20': '-20%',
            }}
          />
        </div>
      );
    };

    const makeTab = (tab: TabDescriptor) => (
      <Tabs.TabPane tab={tab.tabHeader} key={tab.tabHeader} className="operation-form__tab">
        <Row gutter={rowGutter}>
          <Col style={{ overflow: 'visible' }} offset={leftColumnSpan} span={rightColumnSpan}>
            <div style={{ marginLeft: -65 }}>
              <Linegraph
                originalOperationData={originalOperationData}
                businessDays={operationData[tab.content[0].operationDataType]}
                weekendDays={operationData[tab.content[1].operationDataType]}
                type={tab.name}
              />
            </div>
          </Col>
          <Col className="operation-form__label" span={leftColumnSpan}>
            <Typography.Text>Uhrzeiten:</Typography.Text>
          </Col>
          <Col span={rightColumnSpan}>
            {createLegend()}
          </Col>
        </Row>

        {
          tab.content.map((tabContent, index) => (
            <Fragment key={tabContent.key}>
              <Row gutter={rowGutter}>
                <Col span={leftColumnSpan}>
                  <Typography.Text className="operation-form__row-title">{tabContent.title}</Typography.Text>
                </Col>

                <Col span={rightColumnSpan}>
                  { createFields(tabContent.operationDataType) }
                </Col>
              </Row>

              {
                index === 0 && (
                  <Row gutter={[rowGutter, 0]}>
                    <Col span={rightColumnSpan} offset={leftColumnSpan}>
                      <Divider />
                    </Col>
                  </Row>
                )
              }
            </Fragment>
          ))
        }

        {makeSlider(tab)}

      </Tabs.TabPane>
    );

    return (
      <Form layout="horizontal" className="operation-form">
        <div className="operation-form__alert">
          { submitCallbacks && saveableChanges && (
            <Alert
              type="warning"
              message="Daten wurden geändert. Bitte speichern Sie die Daten."
            />
          )}
        </div>

        <Tabs defaultActiveKey="Einsatzhäufigkeiten" tabBarExtraContent={makeTabExtras()}>
          {
            tabs.map(makeTab)
          }
        </Tabs>

        { !submitCallbacks && (
          <Form.Item label="Beschreibung">
            {getFieldDecorator('description', {
              initialValue: initialValues ? initialValues.description : '',
            })(
              <Input.TextArea
                disabled={(readOnlyMode)}
                rows={4}
              />,
            )}
          </Form.Item>
        )}
      </Form>
    );
  },
);

export default Form.create({ name: 'operationForm' })(OperationForm);
