import React, {
  createRef, FC, useContext, useEffect, useState,
} from 'react';
import { Redirect, useParams } from 'react-router-dom';
import {
  Alert, Button, Modal, notification, Spin, Typography,
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { AxiosError } from 'axios';
import { showGeneralError } from '../../api';
import {
  DBOperationDataFull, CreateOperationData,
} from '../../api/types';
import { OperationCard } from './OperationCard';
import { CardBoard } from '../common/CardBoard/CardBoard';
import OperationForm from './OperationForm';
import { DeleteModal } from '../common/DeleteModal';
import { ActiveLocationContext } from '../../context/ActiveLocationStore';
import { OperationDataStore } from '../../context/OperationDataStore';
import { ImportDataModal } from './ImportDataModal';

import '../common/CardBoard/CardBoard.scss';
import { LocationName } from '../common/LocationName';

const earliestPossibleYear = 1994;
const previousYear = (new Date()).getFullYear() - 1;
const yearRange: number[] = [...new Array(previousYear - earliestPossibleYear)].map(
  (_, index) => previousYear - index,
);

export const LocationDetail: FC = () => {
  const [formValidationError, setFormValidationError] = useState(false);
  const [allowedYearsToSelect, setAllowedYearsToSelect] = useState<number[]>([]);
  const [showOperationModal, setShowOperationModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showSpin, setShowSpin] = useState(false);
  const [operationDataToEdit, setOperationDataToEdit] = (
    useState<DBOperationDataFull|undefined>(undefined)
  );
  const [operationDataToDelete, setOperationDataToDelete] = (
    useState<DBOperationDataFull|undefined>(undefined)
  );
  const [notFound, setNotFound] = useState(false);

  const {
    activeLocation,
    setActiveLocation,
    closeActiveLocation,
    deleteData,
    createOrUpdateOperationData,
  } = useContext(ActiveLocationContext);

  const formRef = createRef<FormComponentProps>();
  const { id } = useParams();
  const locationId = Number(id);

  useEffect(() => {
    setActiveLocation(locationId)
      .catch((error: AxiosError) => {
        if (error.response?.status === 404) {
          setNotFound(true);
        }
      });

    return () => closeActiveLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  useEffect(() => {
    const suppliedYears: number[] = activeLocation?.operationDataList.map(
      (data) => data.year,
    ) || [];
    setAllowedYearsToSelect(yearRange.filter((year) => (
      !(suppliedYears.includes(year))
    )));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLocation]);

  if (notFound) return <Redirect to="/notfound" />;

  /**
   * @description Handle the closing of the modal and reset the form fields.
   */
  const closeAndResetModal = (): void => {
    if (formRef && formRef.current) {
      formRef.current.form.resetFields();
    }
    setFormValidationError(false);
    setOperationDataToEdit(undefined);
    setShowSpin(false);
    setShowOperationModal(false);
  };

  /**
   * @description Handle the validating and submitting of the form.
   */
  const handleSave = () => {
    if (formRef && formRef.current) {
      const { form } = formRef.current;

      setShowSpin(true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      form.validateFields(async (err: any, values: Omit<CreateOperationData, 'location'>) => {
        setFormValidationError(err);
        if (!err) {
          const operationDataObject: CreateOperationData = {
            ...operationDataToEdit,
            ...values,
            location: locationId,
          };

          createOrUpdateOperationData(operationDataObject).then(() => {
            form.resetFields();
            closeAndResetModal();
            notification.success({
              placement: 'bottomRight',
              message: 'Einsatzdaten erfolgreich gespeichert!',
            });
          }).catch(() => {
            setShowSpin(false);
            showGeneralError();
          });
        } else {
          setShowSpin(false);
        }
      });
    }
  };

  return (
    <>
      <Spin className="centered-spinner" spinning={!activeLocation}>
        {
          activeLocation && (
            <CardBoard
              onNewElement={() => setShowOperationModal(true)}
              newElementText="Neue Einsatzdaten anlegen"
              additionalNewElement={(
                <Button
                  id="import-from-csv"
                  style={{ marginLeft: 20 }}
                  className="card-board__add-button"
                  type="primary"
                  icon="plus"
                  onClick={() => setShowImportModal(true)}
                >
                  Aus CSV importieren
                </Button>
              )}
              title={(
                <span>
                Einsatzdaten Übersicht für Standort&nbsp;
                  <LocationName />
                </span>
              )}
              description="Wählen oder erstellen Sie Einsatzdaten, um fortzufahren."
              noDataMessage="Legen Sie neue Einsatzdaten an"
              emptyLogoFile="/assets/locationDetail/empty.png"
            >
              {
                activeLocation.operationDataList.map((op: DBOperationDataFull) => (
                  <OperationCard
                    key={op.year}
                    operation={op}
                    onEdit={() => {
                      setOperationDataToEdit(op);
                      setShowOperationModal(true);
                    }}
                    onDelete={() => {
                      setOperationDataToDelete(op);
                    }}
                  />
                ))
              }
            </CardBoard>
          )
        }
      </Spin>

      <Modal
        visible={showOperationModal}
        width="90vw"
        title={
          operationDataToEdit
            ? `Einsatzdaten ${operationDataToEdit.year} bearbeiten`
            : 'Erfassungsjahr oder Szenario wählen'
        }
        onOk={() => handleSave()}
        onCancel={() => closeAndResetModal()}
        okText="Speichern"
        cancelText="Abbrechen"
      >
        <Spin spinning={showSpin}>
          <OperationDataStore
            initialValues={operationDataToEdit}
            allowedYearsToSelect={allowedYearsToSelect}
          >
            <OperationForm
              wrappedComponentRef={formRef}
            />
          </OperationDataStore>

          {
            formValidationError
            && (
              <Alert
                type="error"
                style={{ marginTop: '20px' }}
                message={(
                  <Typography.Text>
                    Bitte wählen Sie ein Erfassungsjahr aus
                    und füllen Sie alle Felder auf beiden Tabs aus.
                  </Typography.Text>
                )}
              />
            )
          }
        </Spin>
      </Modal>

      <ImportDataModal
        closable
        visible={showImportModal}
        afterClose={() => setShowImportModal(false)}
      />

      <DeleteModal
        onOk={() => operationDataToDelete && deleteData(operationDataToDelete)
          .then(() => setOperationDataToDelete(undefined))
          .then(() => notification.success({
            message: 'Einsatzdaten erfolgreich gelöscht.',
            placement: 'bottomRight',
          }))}
        onCancel={() => setOperationDataToDelete(undefined)}
        title={
          `Löschen der Einsatzdaten für ${operationDataToDelete?.year} bestätigen`
        }
        visible={operationDataToDelete !== undefined}
      >
        <Typography.Paragraph>
          Das Löschen der Einsatzdaten und der zugehörigen Daten ist&#160;
          <b>unwiderruflich</b>
          .
        </Typography.Paragraph>
        <Typography.Paragraph>
          Bitte bestätigen Sie das Löschen dieser
          Einsatzdaten durch Eingabe des Wortes &quot;LÖSCHEN&quot; im Feld unten.
        </Typography.Paragraph>
      </DeleteModal>
    </>
  );
};
