import { OperationDataIncomplete } from '../context/types';

export enum OperationDataType {
  MoFrDurations = 'MoFrDurations',
  SaSuDurations = 'SaSuDurations',
  MoFrIncidents = 'MoFrIncidents',
  SaSuIncidents = 'SaSuIncidents',
}

export interface OperationDataComplete extends OperationDataIncomplete {
  MoFrDurations: number[];
  SaSuDurations: number[];
  MoFrIncidents: number[];
  SaSuIncidents: number[];
}

export interface AssessmentData {
  moFrServiceLevelA: number[];
  saSuServiceLevelA: number[];
  moFrServiceLevelB: number[];
  saSuServiceLevelB: number[];
}

export interface WithData {
  data: OperationDataComplete;
}

export enum DataType {
  OperationData = 'operationDataList',
  Forecast = 'forecastList',
  Scenario = 'scenarioList',
}

export interface WithType {
  type: DataType;
}

export interface APIData {
  description?: string;
  location: number;
  year: number;
  name?: string;
}

export interface WithId {
  id: number;
}

export type OperationData = APIData;

export interface Forecast extends APIData {
  name: string;
}

export interface Scenario extends APIData {
  name: string;
}

/*
 * For every type of data (OperationData, Forecasts and Scenarios,
 * we need five different interfaces for different situations.
 * For these I chose the following naming conventions:
 *
 * 1. <Type>FromServerDataIncomplete: Describe the data we get
 *    from the server when we do a location list request. This type
 *    does not include 'type' and 'data'
 *
 * 2. <Type>FromServerFull: Describe the data we get from the server
 *    when we do a detail location request. It does not include 'type'
 *
 * 3. DB<Type>Incomplete: Describe data from location list requests
 *    after we added the 'type' field in the api call in index.ts
 *
 * 4. DB<Type>Full: Describe the data from detail location requests
 *    after we added the 'type' field in the api call in index.ts
 *
 * 5. Create<Type>: The data necessary for the api call to create
 *    a new object of the type. This does not include the 'type'
 *    field, as it is added after we get a response. For Forecast,
 *    it also does not include 'data', because the data is computed
 *    in the backend.
 */

export interface DBDataIncomplete extends APIData, WithId, WithType {}
export interface DBDataFull extends DBDataIncomplete, WithData {}

export interface OperationDataFromServerIncomplete extends OperationData, WithId {}
export interface OperationDataFromServerFull extends OperationDataFromServerIncomplete, WithData {}
export interface DBOperationDataIncomplete extends OperationDataFromServerIncomplete, WithType {
  type: DataType.OperationData;
}
export interface DBOperationDataFull extends DBOperationDataIncomplete, WithData {}
export interface CreateOperationData extends OperationData, WithData {}

export interface ForecastFromServerIncomplete extends Forecast, WithId {}
export interface ForecastFromServerFull extends ForecastFromServerIncomplete, WithData {}
export interface DBForecastIncomplete extends ForecastFromServerIncomplete, WithType {
  type: DataType.Forecast;
}
export interface DBForecastFull extends DBForecastIncomplete, WithData {}
export type CreateForecast = Forecast

export interface ScenarioFromServerIncomplete extends Scenario, WithId {}
export interface ScenarioFromServerFull extends ScenarioFromServerIncomplete, WithData {}
export interface DBScenarioIncomplete extends ScenarioFromServerIncomplete, WithType {
  type: DataType.Scenario;
}
export interface DBScenarioFull extends DBScenarioIncomplete, WithData {}
export interface CreateScenario extends Scenario, WithData {}

export enum LocationType {
  NEF = 'NEF',
  RTW = 'RTW',
}

export enum WeekdayType {
  BusinessDay = 'Mo-Fr',
  Weekend = 'Sa-So',
}

export interface LocationServiceLevels {
  serviceLevelLowerRTW: string;
  serviceLevelUpperRTW: string;
  serviceLevelLowerNEF: string;
  serviceLevelUpperNEF: string;
}

export interface LocationCommon {
  name: string;
  locationType: LocationType;
  street?: string;
  houseNumber?: string;
  plz?: string;
  location?: string;
  latitude?: string;
  longitude?: string;
}

export interface APILocation extends LocationCommon{
  id?: number;
}

export interface DBLocationFromServer extends LocationCommon, WithId {
  operationDataList: OperationDataFromServerIncomplete[];
  forecastList: ForecastFromServerIncomplete[];
  scenarioList: ScenarioFromServerIncomplete[];
}

export interface DBLocation extends DBLocationFromServer {
  operationDataList: DBOperationDataIncomplete[];
  forecastList: DBForecastIncomplete[];
  scenarioList: DBScenarioIncomplete[];
}

export interface DBDetailLocationFromServer extends DBLocationFromServer {
  operationDataList: OperationDataFromServerFull[];
  forecastList: ForecastFromServerFull[];
  scenarioList: ScenarioFromServerFull[];
}

export interface DBDetailLocation extends DBDetailLocationFromServer {
  operationDataList: DBOperationDataFull[];
  forecastList: DBForecastFull[];
  scenarioList: DBScenarioFull[];
}

export interface LoginResponse {
  token: string;
  user: MeResponse;
}

export interface MeResponse extends LocationServiceLevels {
  id: string;
  email: string;
  displayName: string;
  projectName: string;
}

export interface PasswordResetResponse {
  detail: string;
}

export interface UploadCSVResponse {
  numberOfImports: number;
}

export interface FAQArticle {
  id: number;
  title: string;
  body?: string;
}

export interface PrintData {
  data: DBDataFull;
  filename: string;
}
