import React, {
  FC, useContext, useState,
} from 'react';

import { Typography, notification, Spin } from 'antd';
import { CardBoard } from '../common/CardBoard/CardBoard';
import { LocationCard } from './LocationCard';
import { LocationContext } from '../../context/LocationStore';
import LocationModal from './LocationModal';
import { DBLocation } from '../../api/types';
import { DeleteModal } from '../common/DeleteModal';

export const Dashboard: FC = () => {
  const { locationList, deleteLocation } = useContext(LocationContext);

  const [showLocationModal, setShowLocationModal] = useState(false);
  const [locationToBeEdited, setLocationToBeEdited] = useState<DBLocation|undefined>(undefined);
  const [locationToDelete, setLocationToDelete] = useState<DBLocation|undefined>(undefined);

  return (
    <>
      <Spin className="centered-spinner" spinning={!locationList}>
        { locationList && (
          <CardBoard
            onNewElement={() => setShowLocationModal(true)}
            newElementText="neuen Standort anlegen"
            title="Standort auswählen"
            description="Wählen oder erstellen Sie einen Standort, um fortzufahren."
            noDataMessage="Legen Sie neue Standorte an, um Prognosen erstellen zu können."
            emptyLogoFile="/assets/dashboard/empty.png"
          >
            {locationList.map((location: DBLocation) => (
              <LocationCard
                key={location.id}
                onEdit={() => setLocationToBeEdited(location)}
                onDelete={() => setLocationToDelete(location)}
                location={location}
              />
            ))}
          </CardBoard>
        )}
      </Spin>

      <LocationModal
        visible={showLocationModal || locationToBeEdited !== undefined}
        hideModal={() => {
          setShowLocationModal(false);
          setLocationToBeEdited(undefined);
        }}
        initialValues={locationToBeEdited}
      />

      <DeleteModal
        onOk={() => locationToDelete && deleteLocation(locationToDelete.id)
          .then(() => setLocationToDelete(undefined))
          .then(() => notification.success({
            message: 'Standort erfolgreich gelöscht.',
            placement: 'bottomRight',
          }))}
        onCancel={() => setLocationToDelete(undefined)}
        title={`Löschen des Standortes ${locationToDelete && locationToDelete.name} bestätigen`}
        visible={locationToDelete !== undefined}
      >
        <Typography.Paragraph>
          Wenn Sie diesen Standort löschen, löschen Sie auch alle zugehörigen Einsatzdaten,
          Prognosen und Szenarien.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Das Löschen des Standortes und der zugehörigen Daten ist&#160;
          <b>unwiderruflich</b>
          .
        </Typography.Paragraph>
        <Typography.Paragraph>
          Bitte bestätigen Sie das Löschen dieses
          Standortes durch Eingabe des Wortes &quot;LÖSCHEN&quot; im Feld unten.
        </Typography.Paragraph>
      </DeleteModal>
    </>
  );
};
