import React, { FC } from 'react';
import {
  Card, Typography, Button,
} from 'antd';
import { DBDataIncomplete } from '../../api/types';
import '../DashBoard/LocationCard.scss';

interface Props {
  operation: DBDataIncomplete;
  onEdit?: (source: DBDataIncomplete) => void;
  onDelete?: (id: string) => void;
}
export const OperationCard: FC<Props> = ({
  operation,
  onEdit,
  onDelete,
}: Props) => (
  <Card onClick={() => onEdit && onEdit(operation)} hoverable className="location-card">
    <Typography className="location-card__title">
      {`${operation.year || ''} ${operation.name || ''}`}
    </Typography>

    <Typography.Paragraph className="location-card__description">
      {operation.description}
    </Typography.Paragraph>

    <div className="location-card__actions">
      <Button
        className="location-card__link"
        type="link"
        onClick={(e) => {
          e.preventDefault();
          if (onEdit) onEdit(operation);
        }}
      >
        Bearbeiten
      </Button>

      <Button
        className="location-card__link"
        type="link"
        onClick={(e) => {
          e.preventDefault();
          if (onDelete && operation.id) onDelete(operation.id.toString());
        }}
      >
        Löschen
      </Button>
    </div>
  </Card>
);
