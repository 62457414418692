import React, {
  FC, useEffect, useState,
} from 'react';
import { Input, Modal } from 'antd';
import { ModalProps } from 'antd/es/modal';

interface DeleteModalProps extends ModalProps {
  onOk: () => void;
  onCancel: () => void;
  title: string;
  visible: boolean;
}

export const DeleteModal: FC<DeleteModalProps> = ({
  afterClose,
  onOk,
  children,
  ...deleteModalProps
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [disabledOkButton, setDisabledOkButton] = useState<boolean>(true);

  const typedLoschen = inputValue.toLowerCase() === 'löschen';

  useEffect(() => {
    setDisabledOkButton(!typedLoschen);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <Modal
      {...deleteModalProps}
      onOk={onOk}
      afterClose={() => {
        setInputValue('');

        return afterClose && afterClose();
      }}
      okButtonProps={{ disabled: disabledOkButton }}
      cancelText="Abbrechen"
      okText="Löschen bestätigen"
    >
      { children }
      <Input
        onPressEnter={() => typedLoschen && onOk()}
        value={inputValue}
        placeholder="Bitte &quot;LÖSCHEN&quot; hier eingeben"
        onChange={(e) => setInputValue(e.target.value)}
      />
    </Modal>
  );
};
