import React, { FC, FormEvent, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { AxiosError } from 'axios';
import {
  Alert,
  Button, Card, Form, Input, Typography,
} from 'antd';
import { FormComponentProps, ValidationRule } from 'antd/lib/form';
import { passwordConfirmAPI, showGeneralError } from '../../api';

import '../Login/Login.scss';

const password = 'newPassword1';
const passwordRepeat = 'newPassword2';

interface FormValues {
  [password]: string;
  [passwordRepeat]: string;
}

const ResetPasswordForm: FC<FormComponentProps> = ({
  form,
}) => {
  const [success, setSuccess] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const { uid, token } = useParams();

  if (success) return <Redirect to="/" />;

  const {
    getFieldDecorator,
    getFieldValue,
    validateFields,
    isFieldTouched,
  } = form;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    validateFields(async (err, values: FormValues) => {
      if (!err && uid && token) {
        passwordConfirmAPI(
          values[password],
          values[passwordRepeat],
          uid,
          token,
        ).then(() => setSuccess(true))
          .catch((error: AxiosError) => {
            if (error.response?.status === 400
            && 'newPassword2' in error.response.data) {
              setErrorMessages(error.response.data.newPassword2);
            } else {
              showGeneralError();
            }
          });
      }
    });
  };

  const validateRepeatPasswordAfterThis = (
    rule: ValidationRule,
    value: string,
    callback: () => void,
  ) => {
    if (value && isFieldTouched(passwordRepeat)) {
      validateFields([passwordRepeat], { force: true });
    }
    callback();
  };

  const validateRepeatPassword = (
    rule: ValidationRule,
    value: string,
    callback: (msg?: string) => void,
  ) => {
    if (value && value !== getFieldValue(password)) {
      callback('Die Passwörter müssen übereinstimmen!');
    } else {
      callback();
    }
  };

  return (
    <Card className="login__form">
      <Form onSubmit={handleSubmit}>
        <Typography.Paragraph strong>
              Neues Passwort festlegen
        </Typography.Paragraph>

        <Form.Item>
          {getFieldDecorator(password, {
            rules: [
              { required: true, message: 'Bitte geben Sie Ihr Passwort ein.' },
              { validator: validateRepeatPasswordAfterThis },
            ],
            initialValue: '',
          })(
            <Input type="password" placeholder="Passwort" />,
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator(passwordRepeat, {
            rules: [
              { required: true, message: 'Bitte wiederholen Sie Ihr password.' },
              { validator: validateRepeatPassword },
            ],
            initialValue: '',
          })(
            <Input type="password" placeholder="Wiederholtes Passwort" />,
          )}
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">Bestätigen</Button>
        </Form.Item>
      </Form>
      {errorMessages.map((message) => (
        <Alert key={message} type="error" message={message} />
      ))}
    </Card>
  );
};

export default Form.create({ name: 'reset-password' })(ResetPasswordForm);
