import React, { FC, ReactNode } from 'react';
import {
  Button,
  Layout,
  Row,
  Col,
  Typography,
  Alert,
} from 'antd';
import { CardBoardHeader, CardBoardHeaderProps } from './CardBoardHeader';
import { CardBoardContent } from './CardBoardContent';

import './CardBoard.scss';

interface CardBoardProps extends CardBoardHeaderProps{
  onNewElement?: () => void;
  newElementText: string;
  additionalNewElement?: ReactNode;
  noDataMessage: string;
  emptyLogoFile: string;
  disabledButtonInfo?: string;
}

export const CardBoard: FC<CardBoardProps> = ({
  onNewElement,
  newElementText,
  additionalNewElement,
  children,
  noDataMessage,
  emptyLogoFile,
  disabledButtonInfo,
  ...locationHeaderProps
}) => {
  const empty = React.Children.count(children) === 0;

  return (
    <Layout>
      <CardBoardHeader {...locationHeaderProps} />
      <CardBoardContent
        style={{
          display: empty ? 'flex' : 'block',
          alignItems: empty ? 'center' : 'inherit',
          justifyContent: empty ? 'center' : 'inherit',
        }}
      >
        <div style={{
          textAlign: empty ? 'center' : 'left',
          marginBottom: 30,
        }}
        >
          {empty
            && (
              <>
                <img alt="Leer" src={emptyLogoFile} className="card-board__empty-logo" />
                <div className="card-board__empty-apology">
            Leider gibt es noch keine Daten.
                  <br />
                  {noDataMessage}
                </div>
              </>
            )}
          <br />

          <Button
            className="card-board__add-button"
            type="primary"
            icon="plus"
            onClick={onNewElement}
            id="cardboard-new-element"
            disabled={!onNewElement}
          >
            {newElementText}
          </Button>
          {additionalNewElement}

          {
            disabledButtonInfo && (
              <Alert
                style={{ display: 'inline-block' }}
                type="error"
                message={(
                  <Typography.Text>{disabledButtonInfo}</Typography.Text>
                )}
              />
            )
          }
        </div>
        <Row gutter={[70, 25]} type="flex">
          {React.Children.map(children, (child) => (
            <Col>
              {child}
            </Col>
          ))}
        </Row>
      </CardBoardContent>
    </Layout>
  );
};
