import React, { FC, useContext } from 'react';
import { ActiveLocationContext } from '../../context/ActiveLocationStore';

import './LocationName.scss';

export const LocationName: FC = () => {
  const { activeLocation } = useContext(ActiveLocationContext);

  return (
    <span className="location-name">
      {activeLocation?.name || '???'}
    </span>
  );
};
