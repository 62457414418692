import React, { FC, useContext } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import {
  DBDataFull, DBForecastFull, DBOperationDataFull, DBScenarioFull,
} from '../../api/types';
import { ActiveLocationContext } from '../../context/ActiveLocationStore';

interface DataSelectorProps extends SelectProps {
  chosenData: DBDataFull|undefined;
  onChosenDataChange?: (data: DBDataFull) => void;
}

const DataSelector: FC<DataSelectorProps> = ({
  chosenData,
  onChosenDataChange,
  ...selectProps
}) => {
  const { activeLocation } = useContext(ActiveLocationContext);

  if (!activeLocation) return <Select {...selectProps} loading />;

  const selectValue = chosenData ? `${chosenData.type}:${activeLocation[chosenData.type].findIndex(
    (entry: DBDataFull) => entry.id === chosenData.id,
  )}` : undefined;

  const handleSelectChange = (value: string) => {
    if (onChosenDataChange) {
      const [type, i] = value.split(':');
      const index = Number(i);
      switch (type) {
        case 'operationDataList':
          onChosenDataChange(activeLocation.operationDataList[index]);
          break;
        case 'forecastList':
          onChosenDataChange(activeLocation.forecastList[index]);
          break;
        case 'scenarioList':
          onChosenDataChange(activeLocation.scenarioList[index]);
          break;
        default:
          throw new Error('Unknown location type selected.');
      }
    }
  };

  return (
    <Select
      {...selectProps}
      onChange={handleSelectChange}
      value={selectValue}
      defaultValue="operationData:0"
    >
      <Select.OptGroup label="Einsatzdaten">
        {activeLocation.operationDataList.map((data: DBOperationDataFull, index) => (
          <Select.Option
            key={data.id}
            value={`operationDataList:${index}`}
          >
            {data.year}
          </Select.Option>
        ))}
      </Select.OptGroup>
      <Select.OptGroup label="Prognosen">
        {activeLocation.forecastList.map((data: DBForecastFull, index) => (
          <Select.Option
            key={data.id}
            value={`forecastList:${index}`}
          >
            {`${data.year}: ${data.name}`}
          </Select.Option>
        ))}
      </Select.OptGroup>
      <Select.OptGroup label="Szenarios">
        {activeLocation.scenarioList.map((data: DBScenarioFull, index) => (
          <Select.Option
            key={data.id}
            value={`scenarioList:${index}`}
          >
            {data.name}
          </Select.Option>
        ))}
      </Select.OptGroup>
    </Select>
  );
};

export default DataSelector;
