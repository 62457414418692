import React, {
  FC, useContext, useEffect, useState,
} from 'react';
import {
  Typography, Spin, Card, Layout,
} from 'antd';
import { FAQArticle } from '../../api/types';
import { getFAQArticlesListAPI } from '../../api';
import { UserContext } from '../../context/UserStore';
import { CardBoardHeader } from '../common/CardBoard/CardBoardHeader';
import { CardBoardContent } from '../common/CardBoard/CardBoardContent';

export const Faq: FC = () => {
  const [articles, setArticles] = useState<FAQArticle[]>([]);
  const { user, axiosAuthConfig } = useContext(UserContext);

  useEffect(() => {
    getFAQArticlesListAPI(axiosAuthConfig).then((articleList) => setArticles(articleList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]); // Reload whenever the user object changes

  return (
    <Layout>
      <CardBoardHeader title="Die wichtigsten Antworten auf häufig gestellte Fragen" description="zur Bedienung des Prognosetools." />
      <CardBoardContent>
        <Spin className="centered-spinner" spinning={!articles}>
          {articles && articles.map((article) => (
            <Card key={article.id} title={article.title} style={{ marginBottom: '3vw' }}>
              {
              // the filter is used to eliminate empty lines
              article.body && article.body.split('\n').filter((line) => (line)).map((paragraph, i) => (
                // this list is static, so array index as key is ok
                // eslint-disable-next-line react/no-array-index-key
                <Typography.Paragraph key={i}>{paragraph}</Typography.Paragraph>
              ))
            }
            </Card>
          ))}
        </Spin>
      </CardBoardContent>
    </Layout>
  );
};
