import React, { FC } from 'react';
import { Layout } from 'antd';
import './CardBoard.scss';

export interface CardBoardHeaderProps {
  title: string|JSX.Element;
  description: string|JSX.Element;
}

export const CardBoardHeader: FC<CardBoardHeaderProps> = ({
  title,
  description,
}) => (
  <Layout.Header className="card-board__header">
    <div className="card-board__title">
      {title}
    </div>
    <div className="card-board__description">
      {description}
    </div>
  </Layout.Header>
);
