import React, { createRef, useContext, useState } from 'react';
import {
  Alert,
  Modal, notification, Spin,
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { AxiosError } from 'axios';
import LocationModalForm, { EditLocationContext } from './LocationModalForm';
import { APILocation } from '../../api/types';
import { LocationContext } from '../../context/LocationStore';

interface LocationModalProps {
  initialValues?: APILocation;
  visible: boolean;
  hideModal: () => void;
}

/**
 * @description Open a toast to inform the user the location was saved.
 */
const openSuccessfulSaveNotification = (): void => {
  notification.success({
    message: 'Standort erfolgreich gespeichert',
    placement: 'bottomRight',
  });
};

const LocationModal = ({ initialValues, visible, hideModal }: LocationModalProps): JSX.Element => {
  const formRef = createRef<FormComponentProps>();
  const [showSpin, setShowSpin] = useState(false);
  const [formError, setFormError] = useState('');
  const { createOrUpdateLocation } = useContext(LocationContext);

  /**
   * @description Show an error message after a bad request was sent.
   * @param error - Error obtained from axios call.
   */
  const handleError = (error: AxiosError): void => {
    const { response } = error;
    if (response && response.status === 400) {
      let errorMessage = '';
      Object.keys(response.data).forEach((validationError) => {
        errorMessage += `${response.data[validationError]}\n`;
      });
      setFormError(errorMessage);
    }
  };

  /**
   * @description Handle the closing of the modal and reset the form fields.
   */
  const closeAndResetModal = (): void => {
    if (formRef && formRef.current) {
      setFormError('');
      formRef.current.form.resetFields();
      hideModal();
    }
  };

  /**
   * @description Validate the form fields and send them to the API if there are no errors.
   */
  const handleSave = (): void => {
    if (formRef && formRef.current) {
      const { form } = formRef.current;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      form.validateFields(async (err: any, values: any) => {
        if (!err) {
          setShowSpin(true);
          createOrUpdateLocation({ ...initialValues, ...values }).then(() => {
            setShowSpin(false);
            form.resetFields();
            setFormError('');
            hideModal();
            openSuccessfulSaveNotification();
          }).catch((error: AxiosError) => {
            setShowSpin(false);
            handleError(error);
          });
        }
      });
    }
  };

  return (
    <Modal
      title="Standort anlegen"
      visible={visible}
      onCancel={() => closeAndResetModal()}
      onOk={() => handleSave()}
      okText="Speichern"
      cancelText="Abbrechen"
    >
      <Spin spinning={showSpin}>
        <EditLocationContext.Provider
          value={initialValues}
        >
          <LocationModalForm
            wrappedComponentRef={formRef}
          />
        </EditLocationContext.Provider>
        {
          formError !== '' && (
            <Alert
              type="error"
              message={formError}
            />
          )
        }
      </Spin>
    </Modal>
  );
};

export default LocationModal;
