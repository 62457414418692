import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import { Layout } from 'antd';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Login } from './components/Login/Login';
import { ResetPassword } from './components/NewPassword/ResetPassword';
import { Dashboard } from './components/DashBoard/Dashboard';
import { Navigation } from './components/Navigation/Navigation';
import { LocationDetail } from './components/LocationDetail/LocationDetail';
import { Faq } from './components/Faq/Faq';
import { Logout } from './components/Logout';
import { NotFound } from './components/NotFound';
import { Store } from './context/Store';
import { Scenario } from './components/Scenario/Scenario';
import { Forecasts } from './components/Forecasts/Forecasts';
import { ChangePassword } from './components/NewPassword/ChangePassword';

const App: React.FC = () => (
  <div className="app">
    <Store>
      <BrowserRouter>
        <Layout className="app__layout">
          <Navigation />
          <Layout.Content className="app__content">
            <Switch>
              <Route exact path="/login/:withNotification?" component={Login} />
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/change-password" component={ChangePassword} />
              <Route exact path="/reset-password/:uid/:token" component={ResetPassword} />
              <Route exact path="/notfound" component={NotFound} />
              <ProtectedRoute exact path="/location/forecasts/:id(\d+)" component={Forecasts} />
              <ProtectedRoute exact path="/location/scenario/:id(\d+)" component={Scenario} />
              <ProtectedRoute exact path="/location/:id(\d+)" component={LocationDetail} />
              <ProtectedRoute exact path="/faq" component={Faq} />
              <ProtectedRoute exact path="/" component={Dashboard} />
            </Switch>
          </Layout.Content>
        </Layout>
      </BrowserRouter>
    </Store>
  </div>
);

export default App;
