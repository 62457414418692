import React, { FC, useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../context/UserStore';

export const Logout: FC = () => {
  const { logout } = useContext(UserContext);

  useEffect(() => logout());

  return (
    <Redirect to="/login/fromLogout" />
  );
};
