import React, {
  createContext,
  FC,
  useEffect,
  useState,
} from 'react';
import { DBDataFull } from '../api/types';
import { OperationDataIncomplete, OperationDataStoreValue } from './types';

const undefined24: undefined[] = [...new Array(24).fill(undefined)];

export const OperationDataContext = createContext<OperationDataStoreValue>(
  {
    initialValues: undefined,
    allowedYearsToSelect: [],
    readOnlyMode: false,
    operationData: {
      MoFrDurations: undefined24,
      SaSuDurations: undefined24,
      MoFrIncidents: undefined24,
      SaSuIncidents: undefined24,
    },
    setOperationData: () => null,
    originalOperationData: false,
    resetOperationData: () => null,
  },
);

interface OperationDataStoreProps {
  initialValues: DBDataFull|undefined;
  allowedYearsToSelect?: number[];
  readOnlyMode?: boolean;
  showGraphs?: boolean;
  submitCallbacks?: {
    onSave: () => void;
    onDelete: () => void;
  };
}

export const OperationDataStore: FC<OperationDataStoreProps> = ({
  children,
  initialValues,
  ...operationDataStoreProps
}) => {
  const [operationData, setOperationDataState] = useState<OperationDataIncomplete>({
    MoFrDurations: undefined24,
    MoFrIncidents: undefined24,
    SaSuDurations: undefined24,
    SaSuIncidents: undefined24,
  });
  const [originalOperationData, setOriginalOperationData] = useState(false);

  const setOperationData = (data: OperationDataIncomplete) => {
    setOperationDataState(data);
    setOriginalOperationData(false);
  };

  const resetOperationData = () => {
    if (initialValues) {
      setOperationDataState(initialValues.data);
      setOriginalOperationData(true);
    } else {
      setOperationDataState({
        MoFrDurations: undefined24,
        MoFrIncidents: undefined24,
        SaSuDurations: undefined24,
        SaSuIncidents: undefined24,
      });
      setOriginalOperationData(false);
    }
  };

  useEffect(() => {
    resetOperationData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  return (
    <OperationDataContext.Provider value={{
      initialValues,
      operationData,
      setOperationData,
      originalOperationData,
      resetOperationData,
      ...operationDataStoreProps,
    }}
    >
      {children}
    </OperationDataContext.Provider>
  );
};
