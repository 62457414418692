import React, { FC } from 'react';

import ResetPasswordForm from './ResetPasswordForm';

import '../Login/Login.scss';

export const ResetPassword: FC = () => (
  <div className="login">
    <ResetPasswordForm />
  </div>
);
