import React, {
  FC, useContext, useEffect, useState,
} from 'react';
import { notification, Spin, Typography } from 'antd';
import { useParams } from 'react-router';
import { CardBoard } from '../common/CardBoard/CardBoard';
import { ActiveLocationContext } from '../../context/ActiveLocationStore';
import { DBForecastFull } from '../../api/types';
import CreateForecastModal from './CreateForecastModal';
import ViewForecastModal from './ViewForecastModal';
import { ForecastCard } from './ForecastCard';
import { DeleteModal } from '../common/DeleteModal';
import { LocationName } from '../common/LocationName';

export const Forecasts: FC = () => {
  const {
    activeLocation,
    setActiveLocation,
    closeActiveLocation,
    deleteData,
  } = useContext(ActiveLocationContext);
  const [showCreateForecastsModal, setShowCreateForecastsModal] = useState<boolean>(false);
  const [forecastToView, setForecastToView] = useState<DBForecastFull|undefined>();
  const [forecastToDelete, setForecastToDelete] = useState<DBForecastFull|undefined>();
  const { id } = useParams();
  const locationId = Number(id);
  const operationDataNeededForForecast = 2;

  useEffect(() => {
    setActiveLocation(locationId);

    return () => closeActiveLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  return (
    <>
      <Spin className="centered-spinner" spinning={!activeLocation}>
        {
          activeLocation && (
            <CardBoard
              onNewElement={activeLocation.operationDataList.length
              > operationDataNeededForForecast ? (
                  () => setShowCreateForecastsModal(true)
                ) : undefined}
              newElementText="Neue Prognose erstellen"
              title={(
                <span>
                  Übersicht über Prognosen für Standort&nbsp;
                  <LocationName />
                </span>
              )}
              description="Wählen oder erstellen Sie Prognosen, um fortzufahren."
              noDataMessage="Erstellen Sie neue Prognosen"
              emptyLogoFile="/assets/locationDetail/empty.png"
              disabledButtonInfo={
                activeLocation.operationDataList.length > operationDataNeededForForecast
                  ? undefined : 'Speichern Sie zum Erstellen einer Prognose bitte Einsatzdaten von mindestens drei Jahren.'
              }
            >
              {
                activeLocation.forecastList.map((forecast: DBForecastFull) => (
                  <ForecastCard
                    key={`${forecast.year}-${forecast.name}`}
                    forecast={forecast}
                    onDelete={(source) => setForecastToDelete(source)}
                    onOpen={(source: DBForecastFull) => setForecastToView(source)}
                  />
                ))
              }
            </CardBoard>
          )
        }
      </Spin>

      <CreateForecastModal
        afterClose={() => {
          setShowCreateForecastsModal(false);
        }}
        showModal={showCreateForecastsModal}
      />

      <ViewForecastModal
        onClose={() => setForecastToView(undefined)}
        forecast={forecastToView}
      />

      <DeleteModal
        onOk={() => forecastToDelete && deleteData(forecastToDelete)
          .then(() => setForecastToDelete(undefined))
          .then(() => notification.success({
            message: 'Prognose erfolgreich gelöscht.',
            placement: 'bottomRight',
          }))}
        onCancel={() => setForecastToDelete(undefined)}
        title={
          `Löschen der Prognose ${forecastToDelete?.name} bestätigen`
        }
        visible={forecastToDelete !== undefined}
      >
        <Typography.Paragraph>
          Das Löschen der Prognose und der zugehörigen Daten ist&#160;
          <b>unwiderruflich</b>
          .
        </Typography.Paragraph>
        <Typography.Paragraph>
          Bitte bestätigen Sie das Löschen dieser
          Prognose durch Eingabe des Wortes &quot;LÖSCHEN&quot; im Feld unten.
        </Typography.Paragraph>
      </DeleteModal>
    </>
  );
};
