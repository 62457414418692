import React, {
  FC,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Card,
  Layout,
  notification,
  Spin,
  Typography,
} from 'antd';
import { useParams } from 'react-router';
import { ActiveLocationContext } from '../../context/ActiveLocationStore';
import { DataType, DBDataFull } from '../../api/types';
import { CardBoardHeader } from '../common/CardBoard/CardBoardHeader';
import { CardBoardContent } from '../common/CardBoard/CardBoardContent';
import OperationForm from '../LocationDetail/OperationForm';
import { OperationDataStore } from '../../context/OperationDataStore';
import SaveScenarioModal from './SaveScenarioModal';
import { DeleteModal } from '../common/DeleteModal';
import DataSelector from './DataSelector';
import { AssessmentWrapper } from '../Assessment/AssessmentWrapper';
import { LocationName } from '../common/LocationName';


export const Scenario: FC = () => {
  const {
    activeLocation,
    setActiveLocation,
    closeActiveLocation,
    deleteData,
  } = useContext(ActiveLocationContext);
  const { id } = useParams();
  const locationId = Number(id);

  const [chosenData, setChosenData] = useState<DBDataFull|undefined>(undefined);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    setActiveLocation(locationId);

    return () => closeActiveLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  useEffect(() => {
    setChosenData(activeLocation?.operationDataList[0]);
  }, [activeLocation]);

  return (
    <OperationDataStore
      initialValues={chosenData}
      submitCallbacks={{
        onSave: () => setShowSaveModal(true),
        onDelete: () => setShowDeleteModal(true),
      }}
    >
      <Spin className="centered-spinner" spinning={!activeLocation}>
        {
          activeLocation && (
            <Layout>
              <CardBoardHeader
                title={(
                  <span>
                    Übersicht über Szenarien für Standort&nbsp;
                    <LocationName />
                  </span>
                )}
                description={(
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography.Text style={{ marginRight: 10 }}>
                      Wählen Sie einen Einsatzdatensatz oder eine Prognose als Grundlage,
                      oder wählen Sie ein Szenario zum Bearbeiten.
                    </Typography.Text>
                  </div>
                )}
              />
              <CardBoardContent>
                <Card>
                  <Typography.Title level={3} style={{ marginRight: 10, marginBottom: 0 }}>
                      Auswahl der Datengrundlage:
                  </Typography.Title>
                  <Typography>
                    Wählen Sie hier eine Datengrundlage für die Bemessung aus. Sie können
                    dann bei Bedarf die Datengrundlage im Szenarioeditor verändern und als
                    Szenario speichern.
                  </Typography>

                  <div>
                    <DataSelector
                      style={{ width: 200, marginTop: 10, marginBottom: 10 }}
                      chosenData={chosenData}
                      onChosenDataChange={setChosenData}
                    />

                    { chosenData?.description && (
                      <Typography.Paragraph>
                        <strong>Beschreibung:</strong>
                        &nbsp;
                        {chosenData.description}
                      </Typography.Paragraph>
                    )}
                  </div>
                </Card>

                <Card style={{ marginBottom: 40, marginTop: 40 }}>
                  <Typography.Title level={3}>Szenario</Typography.Title>
                  <OperationForm />
                </Card>

                <Card>
                  <Typography.Title level={3}>Bemessungsergebnis</Typography.Title>
                  <Typography.Paragraph>Vorzuhaltende Einsatzmittel</Typography.Paragraph>
                  <AssessmentWrapper />
                </Card>
              </CardBoardContent>
            </Layout>
          )
        }
      </Spin>

      <SaveScenarioModal
        showModal={showSaveModal}
        afterClose={(newScenario) => {
          setShowSaveModal(false);
          if (newScenario) setChosenData(newScenario);
        }}
      />
      <DeleteModal
        onOk={() => {
          if (chosenData?.type === DataType.Scenario && chosenData.id) {
            deleteData(chosenData)
              .then(() => setShowDeleteModal(false))
              .then(() => setChosenData(activeLocation?.operationDataList[0]))
              .then(() => notification.success({
                message: 'Szenario erfolgreich gelöscht.',
                placement: 'bottomRight',
              }));
          }
          setShowDeleteModal(false);
        }}
        onCancel={() => setShowDeleteModal(false)}
        title={`Löschen des Szenarios ${chosenData?.name} bestätigen.`}
        visible={showDeleteModal}
      >
        <Typography.Paragraph>
          Das Löschen des Szenarios und der zugehörigen Daten ist&#160;
          <b>unwiderruflich</b>
          .
        </Typography.Paragraph>
        <Typography.Paragraph>
          Bitte bestätigen Sie das Löschen dieses
          Szenarios durch Eingabe des Wortes &quot;LÖSCHEN&quot; im Feld unten.
        </Typography.Paragraph>
      </DeleteModal>
    </OperationDataStore>
  );
};
