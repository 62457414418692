import React, { FC, useEffect, useState } from 'react';
import {
  Line, Bar, ChartData, defaults,
} from 'react-chartjs-2';
import './Linegraph.scss';
import { ChartOptions, ChartData as ChartJSData } from 'chart.js';

defaults.global.defaultFontFamily = 'Nunito, sans-serif';
defaults.global.defaultFontSize = 14;

export type GraphType = 'incidents'|'durations';

interface Props {
  originalOperationData: boolean;
  businessDays: (number|undefined)[];
  weekendDays: (number|undefined)[];
  type: GraphType;
}

export const Linegraph: FC<Props> = ({
  originalOperationData,
  businessDays,
  weekendDays,
  type,
}: Props) => {
  const [yMax, setYMax] = useState(20);

  const options: ChartOptions = {
    animation: {
      easing: 'linear',
      duration: 1,
    },
    responsive: true,
    legend: {
      display: true,
    },
    // needs to be false to correctly resize to the parent div
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Stundenintervall',
          padding: { top: -20 },
        },
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: type === 'incidents' ? 'Anzahl Einsätze im Jahr' : 'Mittlere Einsatzdauer [min]',
        },
        ticks: {
          autoSkip: true,
          min: 0,
          suggestedMax: yMax,
        },
      }],
    },
    layout: {
      // align it to the table
      padding: {
        left: type === 'incidents' ? 0 : 25,
        right: type === 'incidents' ? 0 : 25,
        top: 0,
        bottom: 0,
      },
    },
    tooltips: {
      enabled: false,
    },
  };

  useEffect(() => {
    const newMax = Math.max(20,
      ...weekendDays.filter((n): n is number => !!n),
      ...businessDays.filter((n): n is number => !!n)) * 1.2;
    if (newMax < 0.5 * yMax || originalOperationData) setYMax(newMax);
    else setYMax(Math.max(newMax, yMax));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekendDays, businessDays]);

  const weekendColor = '#0752a5';
  const businessColor = '#f5a623';

  const data: ChartData<ChartJSData> = {
    labels: new Array(24).fill(''),
    datasets: [
      {
        label: 'Sa-So',
        data: [...weekendDays],
        backgroundColor: type === 'durations' ? 'rgba(0, 0, 0, 0.0)' : weekendColor,
        borderColor: weekendColor,
      },
      {
        label: 'Mo-Fr',
        data: [...businessDays],
        backgroundColor: type === 'durations' ? 'rgba(0, 0, 0, 0.0)' : businessColor,
        borderColor: businessColor,
      },
    ],
  };

  return (
    <>
      <div className="line-graph">
        {
          type === 'durations'
            ? <Line redraw data={data} height={200} options={options} />
            : <Bar redraw data={data} height={200} options={options} />
        }
      </div>
    </>
  );
};
