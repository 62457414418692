import React, {
  FC, useContext, useEffect, useState,
} from 'react';
import { Bar, ChartData, defaults } from 'react-chartjs-2';
import * as chartjs from 'chart.js';
import {
  LocationServiceLevels, LocationType,
} from '../../api/types';
import { UserContext } from '../../context/UserStore';

import './AssessmentGraph.scss';

interface AssessmentGraphProps {
  locationType: LocationType;
  serviceLevelLower: number[];
  serviceLevelUpper: number[];
}

export const AssessmentGraph: FC<AssessmentGraphProps> = ({
  serviceLevelLower,
  serviceLevelUpper,
  locationType,
}: AssessmentGraphProps) => {
  const { user } = useContext(UserContext);
  const [serviceLevels, setServiceLevels] = useState<LocationServiceLevels>({
    serviceLevelLowerNEF: '',
    serviceLevelLowerRTW: '',
    serviceLevelUpperNEF: '',
    serviceLevelUpperRTW: '',
  });

  useEffect(() => {
    if (user) {
      setServiceLevels(user);
    }
  }, [user]);

  defaults.global.animation = {
    duration: 1,
    easing: 'linear',
  };
  defaults.global.defaultFontFamily = 'Nunito, sans-serif';
  defaults.global.defaultFontSize = 14;

  // Calculate the max value for the Y axis
  const yMaxScale = Math.max(...[
    3, ...serviceLevelLower, ...serviceLevelUpper,
  ].filter((n) => (n))) + 1;

  const options: chartjs.ChartOptions = {
    responsive: true,
    legend: {
      display: true,
    },
    // needs to be false to correctly resize to the parent div
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Stundenintervall',
        },
        stacked: true,
        gridLines: {
          display: false,
        },
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Einsatzmittel',
        },
        ticks: {
          min: 0,
          suggestedMax: yMaxScale,
          stepSize: 1,
        },
      }],
    },
  };

  const data: ChartData<chartjs.ChartData> = () => (
    {
      labels: [...new Array(24)].map((_, i) => i),
      responsive: true,
      datasets: [
        {
          label: `Sicherheitsniveau ${locationType === LocationType.NEF ? serviceLevels.serviceLevelLowerNEF : serviceLevels.serviceLevelLowerRTW}%`,
          data: serviceLevelLower,
          backgroundColor: '#0752a5',
          borderColor: 'rgba(0, 0, 0, 1)',
          barThickness: 15,
        },
        {
          label: `Sicherheitsniveau ${locationType === LocationType.NEF ? serviceLevels.serviceLevelUpperNEF : serviceLevels.serviceLevelUpperRTW}%`,
          data: serviceLevelUpper,
          backgroundColor: '#f5a623',
          borderColor: 'rgba(0, 0, 0, 0.0)',
          barThickness: 15,
        },
      ],
    }
  );

  return (
    <div className="planning-graph">
      <Bar redraw data={data} height={200} options={options} />
    </div>
  );
};
